<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-5 px-7 rounded-lg  shadow">
      <v-col cols="12">
        <div class="font-weight-bold text-h6">{{ $t('follow subscription') }}</div>
      </v-col>
      <span v-if="subscriptions.length > 0 && !isLoading">
        <v-card  class="pa-0 py-3 mt-3 gray12  rounded-lg shadow-none overflow-hidden"
          style="border: 1px solid var(--gray11)" v-for="(subscription, index) in subscriptions" :key="index">
          <v-row align="end">

            <v-col cols="12" md="8" class="px-10 py-5">
              <div class="font-weight-bold text-h6">{{ $t('current subscription') }} - {{ subscription.client_name }}
              </div>
              <div class="subtitle-2 font-weight-regular  my-1">
                {{ $t('subscription start date') }} : {{ subscription.start_date }}
              </div>
              <div class="subtitle-2 font-weight-regular  my-1">
                {{ $t('subscription expire in') }} : {{ subscription.end_date }}
                ( {{ subscription.remaining_days > 0 ? subscription.remaining_days : 0 }} {{ $t('day to expire') }})
              </div>
              <div class="subtitle-2 font-weight-regular  my-1">
                {{ $t('duration') }} : {{ subscription.duration }}
              </div>

            </v-col>

            <v-col cols="12" md="4">
              <v-sheet  class="shadow-none gray12 pa-3 py-6  d-flex justify-end align-end">
                <v-btn class="mx-1" color="primary" @click="renewDialog = true; selectedCompany = subscription"
                  depressed>
                  {{ $t('submit renew subscription') }}
                </v-btn>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </span>

      <!-- <v-col v-if="!isLoading" cols="12" class="mt-2">
        <div class=" blue3--text text-center d-flex align-center justify-center cursor_pointer subtitle-1"
          @click="newCompanyDialog = true"
          style="height: 89px ;border: 2px dashed var(--blue3);background-color:  var(--blue12) ; ">
          <v-icon color="blue3" class="mx-2">mdi-plus-circle-outline</v-icon>
          {{ $t('create new company') }}
        </div>
      </v-col> -->

      <span>
        <AnimatedLoading :isLoading="isLoading" />
      </span>

    </v-card>

    <v-dialog v-model="newCompanyDialog" overlay-opacity="0.75" persistent max-width="950">
      <CompanyForm :backValueMethod="() => { newCompanyDialog = false; getData() }" />
    </v-dialog>
    <v-dialog v-model="renewDialog" overlay-opacity="0.75" persistent max-width="950">
      <RenewSubscriptionForm
        :backValueMethod="(reload) => { renewDialog = false; selectedCompany = null; reload ? getData() : '' }"
        :selectedCompany="selectedCompany" />
    </v-dialog>
  </v-container>
</template>
 
<script>
import CompanyForm from '@/components/ui/CompanyForm.vue';
import RenewSubscriptionForm from '@/components/ui/RenewSubscriptionForm.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
export default {
  name: "SubscriptionsPage",

  components: {
    CompanyForm,
    RenewSubscriptionForm,
    AnimatedLoading
  },

  data: () => ({
    title: 'follow subscription',
    subscriptions: [],
    isLoading: false,
    renewDialog: false,
    selectedCompany: null,
    newCompanyDialog: false,
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    this.getData();

  },
  watch: {

  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_my_subscriptions`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          response.data.subscriptions.forEach(client => {
            client.remaining_days = this.$global.SubtractDates(client.end_date, this.$global.GetCurrentDate())
          });
          this.subscriptions = response.data.subscriptions || []
        } else {
          this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
        }
      })
    }
  },

}
</script>
 