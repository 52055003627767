<template>
   <v-card relative class="backgroundW">
      <v-form ref="form" v-model="valid" lazy-validation class="pa-5">
         <v-btn absolute class="ma-2 mt-n3" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="closeDialog">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
         </v-btn>




         <v-card-title class="text-h6 mb-3 font-weight-bold">
            {{ $t('renew subscription request') }}
         </v-card-title>


         <v-row class="py-2">
            <v-col cols="12" md="4" class="py-1">
               <label class="pa-3">{{ $t('duration') | capitalize }} *</label>
               <GenericInput type="number" name="duration" :value="form.renewal_duration"
                  @input="form.renewal_duration = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
               </GenericInput>
            </v-col>

            <v-col cols="12" md="4" class="py-1">
               <label class="pa-3">{{ $t('subscription type') | capitalize }} *</label>
               <GenericInput type="select" :lookups="subscriptions" selected_label="name" selected_prop="id"
                  name="subscription_id" :value="form.renewal_subscription_id"
                  @input="form.renewal_subscription_id = $event" :required="true" :isLoading="isLoading"
                  :cols="[12, 12, 12]">
               </GenericInput>

            </v-col>
            <v-col cols="12">
               <v-divider class="mt-5"></v-divider>
            </v-col>
            <v-col cols="12" v-if="selectedCompany">
               <v-sheet class="gray12 rounded-lg pa-5">
                  <v-row justify="center">
                     <v-col cols="12" lg="5">
                        <div class="text-h6 font-weight-bold">{{ $t('current subscription') | capitalize }}</div>
                        <div class="body-1 font-weight-regular gray7--text">{{ $t('renewed in') | capitalize }}:
                           {{ selectedCompany.start_date }}</div>
                        <div class="body-1 font-weight-regular gray7--text">{{ $t('subscription expire in') | capitalize
                        }}: {{ selectedCompany.end_date }} </div>
                        <div class="body-1 font-weight-regular gray7--text">{{ $t('duration') | capitalize }}:
                           {{ selectedCompany.remaining_days }} </div>
                     </v-col>
                     <v-col cols="12" lg="1">
                        <v-divider vertical></v-divider>
                     </v-col>
                     <v-col cols="12" lg="5">
                        <div class="text-h6 font-weight-bold">{{ $t('subscription after renewal') | capitalize }} </div>
                        <div class="body-1 font-weight-regular">{{ $t('renewal start date') | capitalize }}: </div>
                        <div class="body-1 font-weight-regular gray7--text">{{ $t('subscription expire in') | capitalize
                        }}: {{ RENEWAL_END_DATE }} </div>
                     </v-col>
                  </v-row>
               </v-sheet>
            </v-col>
            <v-col cols="12" class="py-3 px-6">
               <v-btn color="primary" :loading="isSaveLoading" @click="save" :disabled="!(valid)" class="d-block mi-start-auto" min-width="120"
                  height="58" depressed>
                  {{ $t('renew') }}
               </v-btn>
            </v-col>
         </v-row>



      </v-form>
   </v-card>
</template>



<style scoped lang="scss">

</style>



<script>
import GenericInput from './GenericInput.vue';


export default {
   name: "RenewSubscriptionForm",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      selectedCompany: { default: null },
   },
   computed: {
      RENEWAL_END_DATE() {
         if (this.selectedCompany) {
            if (this.form.renewal_duration && this.form.renewal_subscription_id) {
               if (this.form.renewal_subscription_id == 2) {
                  // by year
                  return this.$global.AddToDate(this.selectedCompany.end_date, (Number(this.form.renewal_duration) * 365))
               } else {
                  // by month
                  return this.$global.AddToDate(this.selectedCompany.end_date, (Number(this.form.renewal_duration) * 30))
               }
            } else {
               return ''
            }
         } else {
            return ''
         }
      },
      valid(){
         if(this.form.renewal_subscription_id && this.form.renewal_duration ){
            return true;
         }
         else{
            return false;
         }
      }
   },

   
   components: {
      GenericInput
   },
   data: () => ({
      isLoading: false,
      isSaveLoading: false,
      showPass: false,
      countries: [],
      timezones: [],
      currencies: [],
      subscriptions: [],
      form: {
         renewal_subscription_id: null,
         renewal_duration: null,
      }
   }),
   methods: {
      getData() {
         this.isLoading = true;
         this.$api.GET_METHOD(`create_new_client`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.countries = response.data.countrys || []
               this.currencies = response.data.currencys || []
               this.timezones = response.data.timezones || []
               this.subscriptions = response.data.subscriptions || []
            } else {
               this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
            }
         })
      },
      closeDialog() {

         this.form = {
            renewal_subscription_id: null,
            renewal_duration: null,
         }

         this.backValueMethod(false);
      },
      save() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isSaveLoading = true;
            this.$api.POST_METHOD(`renew_subscription/${this.selectedCompany.client_id}`, this.form).then((response) => {
               this.isSaveLoading = false;
               if (response.check) {
                  this.form = {
                     renewal_subscription_id: null,
                     renewal_duration: null,
                  }
                  this.backValueMethod(true);
               } else {
                  this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
               }
            })
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }

      },

   },
   mounted() {
      // this.$refs.form.validate()
      this.getData();
   },
};
</script>
