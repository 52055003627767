import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    endpointURL: "https://web-api.qawaemerp.com/", // Live
  //  endpointURL: "https://qawaem-web-api.cs-testing.com/",//  Testing
     app_version: process.env.VUE_APP_VERSION,
    apiAccess:
    "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    isAuth: false,
    splashScreen: true,
    offline: false,
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    sidebarIsOpen: false,
    accessToken: null,
    activeSection: 1,
    isHomeSection: false,
    isAboutSection: false,
    isAppSection: false,
    isFeaturesSection: false,
    isBlogSection: false,
    isFeaturesSectionFirst: false,
    isFeaturesSectionLast: false,
    isWatchVideoSection: false,
    isRequestSection: false,
    sections : {
      landing_section: null,
      watch_video: null,
      about_us: null,
    },
    services : [],
    applications : [],
    features : [],
    seo : [],
    register_briefs : [],
    userData : {},
    requiredRule: [(v) => !!v || Vue.prototype.i18n.t('field is required')],
    notRequiredRule: [],
    website_basic_data: {
      about_ar: null,
      about_en: null,
      mission_ar: null,
      mission_en: null,
      vision_ar: null,
      vision_en: null,
      goal_ar: null,
      goal_en: null,
      phone: null,
      email: null,
      sales_phone: null,
      company_phone: null,
      support_phone: null,
      commercial_registration_no: null,
      whatsapp: null,
      facebook_link: null,
      linkedin_link: null,
      twitter_link: null,
      youtube_link: null,
      instagram_link: null,
      whatsapp_link: null,
      privacy_policy: null,
    },
    social_media: [],
    windowSize: { x: 0, y: 0 ,offsetTop: 0},
    showSnackbar:false,
    snackbarType: 'info',
    snackbarTime: 4500,
    snackbarTitle : null,
    snackbarMessages : [],
  },
  mutations: {
    SET_DEFAULTS_DATA(state, data) {
      // Social Links & Sections Data
      const landing_section = data.head_section;
      state.services = data.services || [];
      state.applications = data.app_section || [];
      state.features = data.features || [];
      const watch_video = data.sections.filter(
        (obj) => obj.code === 'watch_video'
      )
      const about_us = data.sections.filter(
        (obj) => obj.code === 'about_us'
      )
      const register_page = data.sections.filter(
        (obj) => obj.code === 'register_page'
      )

      
      state.sections = {
        landing_section: landing_section[0],
        watch_video: watch_video[0],
        about_us: about_us[0],
        register_page: register_page[0],
      };

      // state.features = data.features;
      state.register_briefs =  data.register_briefs;
      // state.seo =  data.seo,
      state.website_basic_data = data.basic_data[0] || {
        about: null,
        mission: null,
        vision: null,
        goal: null,
        main_logo: null,
        footer_logo: null,
        footer_desc: null,
        phone: null,
        email: null,
        whatsapp: null,
        facebook_link: null,
        linkedin_link: null,
        twitter_link: null,
        youtube_link: null,
        instagram_link: null,
        whatsapp_link: null,
        commercial_registration_no: null,
        support_phone: null,
        company_phone: null,
        sales_phone: null,
      };
      state.social_media = data.social_media
      //
      state.splashScreen = false;
    },
  },
  getters: {

  },
  actions: {
    checkAuth({ state }) {
     // state.splashScreen = true;
      // this.$axios.defaults.config.baseURL = ''
      state.isAuth = !!localStorage.getItem(
        Vue.prototype.$global.CRYPT("cloud", "access-token")
      );
      if (!state.isAuth) {
      //  state.splashScreen = false;
        // this.app.router.push('/login')
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common.Authorization =
          "Bearer " + state.accessToken;
        // state.isAuth = true
      }
    },
    getUserData() {
      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        if (response.check) {
          console.log('response.data',response.data);
          Vue.prototype.$api.SET_AUTH(response.data);
        }
      });
    },
    
    checkAppLanguage({ state }) {
      return state.language;
    },
    checkLang({ state }) {
      Vue.prototype.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
      state.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
      state.Settings.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    },
    getWebsiteData({ state, commit}){
      axios.defaults.baseURL = state.endpointURL + `api/v1/${state.Settings.language}/front/`;
      setTimeout(() => {
        Vue.prototype.$api.GET_METHOD(`home`).then((response) => {
          if (response.check) {
            commit("SET_DEFAULTS_DATA", response.data);
          }
        })
      }, 1);
    },
  },

  modules: { Settings },
});
