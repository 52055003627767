<template>
  <div :style="'height :' + height" :class="classes">
    <img src="@/assets/logo.svg" class="logo0" :height="height" alt="Qawaem" />
    <!-- <img src="@/assets/img/svg/logo1.svg" class="logo1" :height="height" alt="Qawaem" />
    <img src="@/assets/img/svg/logo2.svg" class="logo2" :height="height" alt="Qawaem" />
    <img src="@/assets/img/svg/logo3.svg" class="logo3" :height="height" alt="Qawaem" /> -->
  </div>
</template>


<script>
export default {
  name: "AnimatedLogo",
  props: {
    height: { default: 100 },
    classes: { default: "d-block" },
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">
.logo1,
.logo3,
.logo2 {
  position: absolute;
  display: block;
  animation: logoAnimate 1.6s infinite;
}

.logo0 {
  position: absolute;

}

.logo1 {
  animation-delay: 0s;
}

.logo2 {
  animation-delay: 2s;
}

.logo3 {
  animation-delay: 4s;
}

@keyframes logoAnimate {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes logoAnimate {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes logoAnimate {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes logoAnimate {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
