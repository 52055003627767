<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-5 px-7 rounded-lg shadow">

      <span v-if="clients.length > 0 && !isLoading">
        <v-card  class="mt-3 companyCard rounded-lg shadow-none " style="border: 1px solid var(--gray11)" v-for="(client, index) in clients" :key="index">
          <v-row align="center">
            <v-col cols="12" md="12" lg="2" class="pa-5 px-7">
              <v-avatar  class="rounded-0 d-block ma-auto" size="125" v-lazy-container="{ selector: 'img' }">
                <img class="pa-1" :data-src="$api.serverUrl + client.client_logo"
                  :alt="client.client_name" />
              </v-avatar>
            
            </v-col>
            <v-col cols="12" md="12" lg="6" class="px-4 text-center text-lg-start py-5" >
              <div class="subtitle-2">#{{ client.client_id }}</div>
              <div class="font-weight-bold text-h6">{{ client.client_name }}</div>
              <div class="subtitle-2 font-weight-regular">
                {{ $t('subscription expire in') }} : {{ client.end_date }}
                ( {{ client.remaining_days > 0 ? client.remaining_days : 0 }} {{ $t('day to expire') }})
              </div>
              <div class="subtitle-2 font-weight-regular">
                  <v-btn text color="primary" :disabled="!($store.state.userData.user_active)" @click="openERP(client.client_id)">https://erp.qawaemerp.com</v-btn>
              </div>
              
            </v-col>
            <v-col cols="12" md="12" lg="4" class="pa-0" v-if="client.client_status == 1">
              <v-sheet  class="companyCardSheet shadow-none pa-3 py-6 gray5">
                <div class="font-weight-bold white--text text-h6">{{ $t('activation is suspended until payment is completed') }}</div>
                <div class="subtitle-2 white--text font-weight-regular">{{ $t('you will be contacted as soon as possible for the payment and activation process') }}</div>
              </v-sheet>
            </v-col>

            <v-col cols="12" md="12" lg="4"  v-if="client.client_status == 2">
              <v-sheet  class="companyCardControl shadow-none pa-3 py-6  d-flex justify-end align-end">
                <v-btn  class="green12 mx-1 success--text" :disabled="!($store.state.userData.user_active)"  @click="renewDialog = true; selectedCompany = client" depressed>
                  {{ $t('renew subscription') }}
               </v-btn>
               <v-btn color="primary" class=" mx-1 " :disabled="!($store.state.userData.user_active)" @click="openERP(client.client_id)" depressed>
                  {{ $t('enter') }}
               </v-btn>
              </v-sheet>
           </v-col>
          </v-row>
        </v-card>
      </span>

      <v-col v-if="(!isLoading && $store.state.userData.user_active)" cols="12" class="mt-2">
        <div class=" blue3--text text-center d-flex align-center justify-center cursor_pointer subtitle-1"
          @click="newCompanyDialog = true"
          style="height: 89px ;border: 2px dashed var(--blue3);background-color:  var(--blue12) ; ">
          <v-icon color="blue3" class="mx-2">mdi-plus-circle-outline</v-icon>
          {{ $t('create new company') }}
        </div>
      </v-col>

      <span>
        <AnimatedLoading :isLoading="isLoading" />
      </span>

    </v-card>

    <v-dialog v-model="newCompanyDialog" overlay-opacity="0.75" persistent max-width="950">
      <CompanyForm :backValueMethod="()=>{newCompanyDialog = false; getData()}" />
    </v-dialog>
    <v-dialog v-model="renewDialog" overlay-opacity="0.75" persistent max-width="950">
      <RenewSubscriptionForm :backValueMethod="(reload)=>{renewDialog = false;selectedCompany=null; reload ? getData() : ''}" :selectedCompany="selectedCompany" />
    </v-dialog>
  </v-container>
</template>
 
<script>
import CompanyForm from '@/components/ui/CompanyForm.vue';
import RenewSubscriptionForm from '@/components/ui/RenewSubscriptionForm.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
export default {
  name: "CompanyPage",

  components: {
    CompanyForm,
    RenewSubscriptionForm,
    AnimatedLoading
},

  data: () => ({
    title: 'companies',
    clients: [],
    isLoading: false,
    renewDialog: false,
    selectedCompany: null,
    newCompanyDialog: false,
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    this.getData();

  },
  watch: {
    //  $route() {;
    //    this.getData();
    //  },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_my_clients`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          response.data.clients.forEach(client => {
            client.remaining_days = this.$global.SubtractDates(client.end_date, this.$global.GetCurrentDate())
          });
          this.clients = response.data.clients || []
        } else {
          this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
        }
      })
    },
    openERP(client) {
      window.open(`https://erp.qawaemerp.com/login?clientID=${client}`, '_blank')
    },
  },

}
</script>
 

<style lang="scss" >
  .companyCard{
    height: 160px;
    overflow: hidden !important;
     padding: 0 !important;
    @media screen and (max-width: 1263px) {
      height: auto;
      overflow: visible;
 //     padding: 3rem !important;
    }
    .companyCardControl{
      height: 160px;
      @media screen and (max-width: 1263px) {
        height: auto;
      }
     }
    .companyCardSheet{
      height: 180px;
      padding: 2rem 1.5rem !important;
      @media screen and (max-width: 1263px) {
        padding: 3rem 4.5rem !important;
      }
      @media screen and (max-width: 580px) {
        padding: 2rem 2.5rem !important;
      }

    }
  }
</style>