<template>
   <v-card relative :class="freeTrial ? 'glass-effect': 'backgroundW'">
      <v-form ref="form" v-model="valid" lazy-validation class="pa-5" v-if="!isSaveLoading">
         <v-btn absolute class="ma-2 mt-n3" v-if="!freeTrial" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="closeDialog">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
         </v-btn>


         <v-col cols="12" class="pa-0">
            <v-tabs color="success" class="transparent" centered v-model="tab">
               <v-tabs-slider></v-tabs-slider>
               <v-tab class="font-weight-bold" :disabled="!freeTrial" :class="tab == 0 ?'green12' : ''"  @click="tab = 0" >
                  <strong>{{ $t('personal data') }}</strong>
               </v-tab>
               <v-tab class="font-weight-bold" :class="tab == 1 ?'green12' : ''" active :value="1" @click="tab = 1">
                  <strong>{{ $t('company data') }}</strong>
               </v-tab>
               <v-tab class="font-weight-bold" :class="tab == 2 ?'green12' : ''" :value="2" @click="tab = 2">
                  <strong>{{ $t('registration data') }}</strong>
               </v-tab>
            </v-tabs>
         </v-col>

         <div class="text-h6 my-3 font-weight-bold">
            {{ freeTrial ? $t('enter your data and create your company first, to start the experiment') : $t('create new company') }} 
         </div>

         <div class="caption mb-5" v-if="freeTrial">
            {{$t('free, simple steps and start using')}}   
         </div>
         <v-tabs-items class="transparent" v-model="tab">
            <v-tab-item eager :disabled="!freeTrial" :value="0">
               <v-row class="py-2">
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('first name') | capitalize }} *</label>
                     <GenericInput type="text" name="first_name" :value="form.first_name"
                        @input="form.first_name = $event"  :required="(freeTrial)" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('last name') | capitalize }} *</label>
                     <GenericInput type="text" name="last_name" :value="form.last_name"
                        @input="form.last_name = $event"  :required="(freeTrial)" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('mobile') | capitalize }} *</label>
                     <GenericInput type="text" name="mobile_phone" :value="form.mobile"
                        @input="form.mobile = $event"  :required="(freeTrial)" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <!-- <v-col cols="12" md="6" class="py-1">
                     <label class="pa-3">{{ $t('landline') | capitalize }} *</label>
                     <GenericInput type="mobile" name="landline" :value="form.landline"
                        @input="form.landline = $event"  :required="(freeTrial)" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col> --> 

                  <v-col cols="12" class="py-5 px-6">
                     <v-btn color="primary" :disabled="!(form.first_name && form.last_name && form.mobile)" @click="tab = 1" class="d-block mi-start-auto" min-width="120" height="58"
                        depressed>
                        {{ $t('next') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-tab-item>
            <v-tab-item eager :value="1">
               <v-row class="py-2">
                  <v-col cols="12" class="py-1">
                     <label class="pa-3">{{ $t('company name') | capitalize }} *</label>
                     <GenericInput type="text" name="client_name" :value="form.client_name"
                        @input="form.client_name = $event"  :required="true" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12" class="py-1">
                     <label class="pa-3">{{ $t('address') | capitalize }} *</label>
                     <GenericInput type="text" name="address" :value="form.address" @input="form.address = $event"
                         :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('commercial register') | capitalize }} *</label>
                     <GenericInput type="number" name="commercial_registration_no"
                        :value="form.commercial_registration_no" @input="form.commercial_registration_no = $event"
                         :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('vat register number') | capitalize }} </label>
                     <GenericInput type="number" name="vat_register_no" :value="form.vat_register_no"
                        @input="form.vat_register_no = $event"  :required="false" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>

                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('city') | capitalize }} *</label>
                     <GenericInput type="select" :lookups="cities" name="city_id" selected_label="name" selected_prop="city_id" :value="form.city_id"
                        @input="form.city_id = $event"  :required="true" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>

                  <v-col cols="12" class="py-5 d-flex justify-space-between px-6">
                     <v-btn color="primary" v-if="freeTrial" text @click="tab = 0" min-width="120" height="58" depressed>
                        {{ $t('prev') }}
                     </v-btn>
                     <v-btn color="primary"
                     :disabled="!(form.client_name && form.address && form.commercial_registration_no && form.city_id )"
                     @click="tab = 2" class="d-block mi-start-auto" min-width="120" height="58"
                        depressed>
                        {{ $t('next') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-tab-item>
            <v-tab-item eager :value="2">
               <v-row class="py-2">
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('company username') | capitalize }} *</label>
                     <GenericInput type="text" name="client_user" :value="form.client_user"
                        @input="form.client_user = $event"  :required="true" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12"  class="py-1">
                     <label class="pa-3">{{ $t('password') | capitalize }} *</label>
                     <GenericInput type="password" name="client_pass" :value="form.client_pass"
                        @input="form.client_pass = $event"  :required="true" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12"  class="py-1 align-center d-flex" v-if="subscriptions.length > 0">
                     <label class="pa-3 cursor_pointer" @click="form.subscription_id = subscriptions[0].id">{{ subscriptions[0].name | capitalize }}</label>
                     <v-switch color="primary" class="notFalsySwitch" :false-value="subscriptions[0].id" :true-value="subscriptions[1].id" hide-details v-model="form.subscription_id" inset />
                     <label class="pa-3 cursor_pointer" @click="form.subscription_id = subscriptions[1].id">{{ subscriptions[1].name | capitalize }}</label>
                  </v-col>
                  <v-col cols="12" class="pa-3">
                     <div class="primary--text body-2 px-3">
                        {{ $t(
                              'save this data well in order to enter the program, you will need the name and password when entering the program every time, as this account is considered the main account or the program manager (company manager), and it can be modified later'
                           )
                        }}
                     </div>
                     <v-divider class="mt-5"></v-divider>
                  </v-col>
                  <v-col cols="12"  class="py-1">
                     <GenericInput type="dropzone" name="client_logo" label="add a picture of your company logo" :value="form.client_logo"
                        @input="form.client_logo = $event"  :required="true" :isLoading="isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="12" class="py-5 d-flex justify-space-between px-6">
                     <v-btn color="primary" text @click="tab = 1" min-width="120" height="58" depressed>
                        {{ $t('prev') }}
                     </v-btn>
                     <v-btn color="primary" :loading="isSaveLoading" :disabled="!(valid)" @click="save" height="58" min-width="120" depressed>
                        {{ $t('register the company now') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </v-tab-item>
         </v-tabs-items>
      </v-form>
         <div class="py-16" v-if="isSaveLoading">
            <div class="text-h4 font-weight-bold text-center" v-if="freeTrial">{{$t('welcome in Qawaem') | capitalize}}</div>
            <img class="d-block ma-auto" :height="200" src="@/assets/img/createCompany.gif" alt="">
            <div class="subtitle-1 mt-10 my-5 text-center">{{$t('do not close the page, moments and start using.') | capitalize}}</div>
            <div class="text-h6 text-center">{{$t('the program is prepared for you') | capitalize}}</div>
         </div>
   </v-card>
</template>



<style scoped lang="scss">

</style>



<script>
import GenericInput from './GenericInput.vue';


export default {
   name: "CompanyForm",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      freeTrial: { default: false },
   },
   computed: {

   },

   components: {
      GenericInput
   },
   data: () => ({
      isLoading: false,
      isSaveLoading: false,
      showPass: false,
      tab: 0,
      valid: false,
      countries: [],
      timezones: [],
      currencies: [],
      cities: [],
      subscriptions: [],
      form: {
         client_name: null,
         address: null,
         commercial_registration_no: null,
         vat_register_no: null,
         country_id: null,
         currency_id: null,
         timezone_id: null,
         city_id: null,
         area: null,
         postal_code: null,
         subscription_id: null,
         duration: null,
         client_user: null,
         client_pass: null,
         client_logo: null,
      }
   }),
   watch: {
      'form.country_id'(){
         this.form.currency_id = this.form.country_id ? this.$global.FilterArrayOfObjectElement(this.countries, 'country_id', this.form.country_id, 'currency_id') : null;
         this.form.timezone_id = this.form.country_id ? this.$global.FilterArrayOfObjectElement(this.countries, 'country_id', this.form.country_id, 'timezone_id') : null;
      }
   },
   methods: {
      getData() {
         this.isLoading = true;
         this.$api.GET_METHOD(`create_new_client`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.countries = response.data.countrys || [];
               this.currencies = response.data.currencys || [];
               this.timezones = response.data.timezones || [];
               this.subscriptions = response.data.subscriptions || [];
               this.form.subscription_id = response.data.subscriptions.length?  response.data.subscriptions[0].id : 0;
               this.cities = response.data.citys || [];
               this.form.city_id = response.data.citys.length?  response.data.citys[0].id : null;
               // this.form.country_id = response.data.countrys.length > 0 ? response.data.countrys[0].country_id : null;
               // this.form.timezone_id = response.data.timezones.length > 0 ? response.data.timezones[0].timezone_id : null;
               // this.form.currency_id = response.data.currencys.length > 0 ? response.data.currencys[0].currency_id : null;
            } else {
               this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
            }
         })
      },
      closeDialog() {

         this.form = {
            client_name: null,
            address: null,
            commercial_registration_no: null,
            vat_register_no: null,
            country_id: null,
            currency_id: null,
            timezone_id: null,
            city: null,
            area: null,
            postal_code: null,
            subscription_id: null,
            duration: null,
            client_user: null,
            client_pass: null,
            client_logo: null,
         }
         if(this.freeTrial){
         this.tab = 0
      }else{
         this.tab = 1
      }
         this.backValueMethod();
      },
      save() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isSaveLoading = true;
           if(this.freeTrial){
            this.backValueMethod(true);
            this.$api.POST_METHOD(`make_free_trial`, this.form).then((response) => {
               setTimeout(() => {
                  this.isSaveLoading = false;
                  this.backValueMethod(false);
               }, 1000);
               if (response.check) {
                  this.$router.push('/profile')
                  this.$store.state.userData.free_trial = 0;
                  // window.open(`https://erp.qawaemerp.com/login?clientID=${response.data}`, '_blank')
               }else{
                 this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
               }
            })
           }else{
            this.$api.POST_METHOD(`add_new_client`, this.form).then((response) => {
               this.isSaveLoading = false;
               if (response.check) {
                  this.closeDialog();
                  // window.open(`https://erp.qawaemerp.com/login?clientID=${response.data}`, '_blank')
               }else{
                 this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
               }
            })
           }
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }

      },

   },
   mounted() {
      // this.$refs.form.validate()
      this.getData();
      if(this.freeTrial){
         this.tab = 0
      }else{
         this.tab = 1
      }
   },
};
</script>
