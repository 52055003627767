<template>
  <v-app v-resize="onResize" v-scroll="onScroll">

    <v-main class="pa-0">
      <SplashLoader v-if="$store.state.splashScreen == true" />
      <SideBar />


      <v-container fluid class="pa-0" v-if="!$store.state.splashScreen">
        <AppBar />
        <UserNotActive />
        <router-view></router-view>
      </v-container>
      <PageFooter />
      <v-snackbar multi-line top :left="!$vuetify.rtl" color="gray3" :right="$vuetify.rtl"
        v-model="$store.state.showSnackbar" :timeout="4000">
        <div :class="`${$store.state.snackbarType}--text`">{{ $t($store.state.snackbarTitle) }}</div>
        <div class="body-1" v-for="(message, index) in $store.state.snackbarMessages" :key="index">{{ $t(message) }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn :color="$store.state.snackbarType" text v-bind="attrs" @click="$store.state.showSnackbar = false">
            {{ $t('hide') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-slide-y-reverse-transition>
        <Whatsapp v-if="!$store.state.splashScreen && $store.state.windowSize.offsetTop >= 300" />
      </v-slide-y-reverse-transition>

    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>
import SplashLoader from "@/components/ui/SplashLoader.vue";
import PageFooter from '@/components/PageFooter.vue';
import AppBar from '@/components/ui/Appbar.vue';
import SideBar from '@/components/ui/SideBar.vue';
import UserNotActive from '@/components/ui/UserNotActive.vue';
import axios from "axios";
import Whatsapp from "./components/ui/Whatsapp.vue";
export default {
  name: "App",
  components: { AppBar, PageFooter, SideBar, UserNotActive, SplashLoader, Whatsapp },
  data: () => ({
    isLoading: false,
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  mounted() {
    this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    // this.$store.dispatch("checkRoute");
    if (this.$route.path == '/en') {
      localStorage.setItem("language", 'en');
      this.$i18n.locale = 'en';
      this.$vuetify.rtl = false;
      this.$store.state.language = 'en';
      this.$store.state.Settings.language = 'en';
      axios.defaults.baseURL = this.$store.state.endpointURL + "api/v1/en/front/";
    } else {
      localStorage.setItem("language", 'ar');
      this.$i18n.locale = 'ar';
      this.$vuetify.rtl = true;
      this.$store.state.language = 'ar';
      this.$store.state.Settings.language = 'ar';
      axios.defaults.baseURL = this.$store.state.endpointURL + "api/v1/ar/front/";
    }
    document.addEventListener('swUpdated', this.swUpdated, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
    document.addEventListener('swReady', this.swReady, { once: true });
  },
  watch: {
    $route() {
      axios.defaults.baseURL = this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/front/`;
      // this.$store.dispatch("checkRoute");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    showSnackbar() {
      if (!this.showSnackbar) {
        setTimeout(() => {
          this.$store.state.snackbarType = 'info';
          this.$store.state.snackbarTime = 4500;
          this.$store.state.snackbarTitle = null;
          this.$store.state.snackbarMessages = [];
        }, 100);
      }
    }
    // '$store.state.Settings.language'() {
    //   axios.defaults.baseURL = this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/front/`;
    //   // this.$store.dispatch("checkRoute");
    // }
  },
  created() {
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
  },
  computed: {
    showSnackbar() {
      return this.$store.state.showSnackbar
    }
  },
  methods: {
    onResize() {
      this.$store.state.windowSize = { x: window.innerWidth, y: window.innerHeight, offsetTop: 0 }
    },
    onScroll() {
      console.log(window.pageYOffset);
      this.$store.state.windowSize.offsetTop = window.pageYOffset
    },
    swUpdated(event) {
      this.registration = event.detail
      this.refreshApp()
    },
    swUpdateFound() {
      // this.$router.push('/')
    },
    swOffline() {
      this.$store.state.networkConnection = false;
      this.$global.ShowAlert('info', this.$i18n.t('No Internet Connection'));
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.$router.push('/')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    swReady() {
      if (this.$route.fullPath == '/system_update') {
        this.$router.push('/')
      }
    }
  },
};
</script>
