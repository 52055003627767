<template>
  <!-- white  pa-7 px-lg-16 px-md-1 px-xs-2 my-16 -->
  <div data-aos="fade-up" data-aos-duration="1000" id="features" class="benefits-section text-capitalize ">
    <header class="ma-11 text-center">
      <h2 class="text-h4 font-weight-black ma-5">{{ $t("Features") }}</h2>
    </header>
    <v-row class="row-cards justify-center">

      <v-col v-for="feature in $store.state.features" :key="feature.id" cols="12" sm="6" md="4" lg="4" data-aos="fade-down"
        data-aos-duration="1000">
        <div class="card mt-7">
          <div class="icon-card">

            <v-img contain :lazy-src="$store.state.endpointURL + feature.image"
            v-if="(feature.image)" height="45" width="45" :alt="feature.title"
            class="rounded-lg overflow-hidden  d-block ma-auto "
            :src="$store.state.endpointURL + feature.image">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-skeleton-loader class="mx-auto" max-width="320"
                        type="image"></v-skeleton-loader>
                </v-row>
            </template>
        </v-img>
        <img src="@/assets/img/default.svg" v-else :alt="feature.title">

            <!-- <img :src="$store.state.endpointURL + feature.image" alt="" /> -->
          </div>
          <div class="title text-h6 gray1--text font-weight-bold text-center">
            {{ feature.title | capitalize }}
          </div>
          <p class="text-subtitle-1 gray7--text font-weight-regular pa-3" v-html=" feature.description" />
          <!-- <v-expand-transition>
            <div v-show="show">

              <v-card-text class=" gray7--text font-weight-regular">
                {{ data.description }}
              </v-card-text>
            </div>
          </v-expand-transition>
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn> -->
        </div>
      </v-col>

    </v-row>

  </div>
</template>

<script>



export default {
  name: "WhyQawaemSection",
  data: () => ({
    show: false,
  }),
  props: {
    'pageCode': Object,
  },

};
</script>

<style lang="scss" scoped>
.benefits-section {
  position: relative;
  margin-top: -29px;
  z-index: 200;
  overflow: hidden;
}

.square-big {
  position: absolute;
  left: 246px;
  z-index: -2;
}

header {
  line-height: 3.6;
}

p {
  max-width: 700px;
  line-height: 1.6;
  margin: auto;
  display: -webkit-box;
  transition: 0.3s;
  padding-top: 15px;
}

.icon-card {
  width: 90px !important;
  height: 90px !important;
  display: flex;
  border-radius: 50%;
  background: #ffffff;
  margin: 4px auto 24px;
}

.icon-card img {
  max-height: 45px;
  width: 45px;
  margin: auto;
}

.card {
  max-width: 400px;
  margin: auto;
  height: 335px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(132, 132, 132, 0.312);
  border-radius: 23px;
  z-index: 500;
  position: relative;
  overflow: hidden;
}

.card p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.row-cards {
 background: url('../../assets/img/png/whyQawaem.webp');
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  margin: 0;
  background-attachment: fixed;
  position: relative;
  padding: 29px 0;



  .icon-card {
    box-shadow: 0px 1px 34px rgba(26, 80, 255, 0.16) !important;
  }

  //.col-12:nth-of-type(1) {
  //  .icon-card {
  //    box-shadow: 0px 8px 38px rgba(27, 195, 0, 0.3);
  //  }
  //}
//
  //.col-12:nth-of-type(2) {
  //  .icon-card {
  //    box-shadow: 0px 8px 41px rgba(50, 74, 94, 0.22);
  //  }
  //}
//
  //.col-12:nth-of-type(3) {
  //  .icon-card {
  //    box-shadow: 0px 8px 55px rgba(255, 205, 29, 0.24);
  //  }
  //}
//
  //.col-12:nth-of-type(4) {
  //  .icon-card {
  //    box-shadow: 0px 8px 24px rgba(53, 95, 158, 0.1);
  //  }
  //}
//
  //.col-12:nth-of-type(5) {
  //  .icon-card {
  //    box-shadow: 0px 8px 24px rgba(53, 95, 158, 0.1);
  //  }
  //}
//
  //.col-12:nth-of-type(6) {
    .icon-card {
      box-shadow: 0px 8px 55px rgba(255, 205, 29, 0.24);
    }
  //}
}

.row-cards:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 53%);

}

@media (max-width:1024px) {
  .row-cards {
    background: unset
  }

  .row-cards:before {
    background: unset
  }
}

// .row-cards:before {
//   content: "";
//   position: absolute;
//   background: rgba(0, 37, 61, 0.5);
//   top: 55%;
//   left: 50%;
//   width: 91%;
//   height: 48%;
//   transform: translate(-50%, -50%);
// }
</style>
