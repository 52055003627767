<template>
  <section class="py-16">
    <v-container fluid class="px-5 my-16 px-md-12">
      <v-row class="services_row" v-if="!isLoading">
        <v-col cols="12" md="6" class="content">
          <div>
            <h1 class="mb-8 font-weight-bold">
              {{ service.title | capitalize }}
            </h1>
            <p v-html="service.description" />
          </div>
        </v-col>
        <v-col cols="12" md="6" class="img_col">
          <div class="back_layer">
            <v-img contain :lazy-src="$store.state.endpointURL + service.small_image"
              v-if="(service.small_image || service.image)" :aspect-ratio="600 / 400" :alt="service.title"
              class="rounded-lg overflow-hidden d-block ma-auto cursor_pointer"
              :src="$store.state.endpointURL + service.image">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
                  <v-skeleton-loader class="d-block mi-start-auto" max-width="320" type="image"></v-skeleton-loader>
                </v-row>
              </template>
            </v-img>
            <img src="@/assets/img/default.svg" class="d-block mi-start-auto" height="300" v-else :alt="service.title">
          </div>
        </v-col>
      </v-row>

      <AnimatedLoading :isLoading="isLoading" />

    </v-container>
  </section>
</template>
 
<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';


export default {
  name: "ServicePage",
  components: {
    AnimatedLoading
  },
  data: () => ({
    service: Object,
    isLoading: false,
    seo: [],
  }),
  head() {
    return {
      title: this.$route.params.name,
      meta: this.seo,
    }
  },
  mounted() {
    this.getData()

  },
  watch: {
    $route() {

    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`service/${this.$route.params.id}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.service = response.data.data
        }
      })
    }
  },

}
</script>
 