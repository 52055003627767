<template>
  <section id="profile-page">
    <v-container fluid class="px-7 px-lg-16">

      <v-row>
        <v-col cols="12" md="4" lg="3" class="px-0 px-lg-2">
          <v-card class="py-1 px-0 px-lg-3 shadow rounded-lg">
            <v-list class="overflow-hidden  rounded-lg">

              <v-list-item active-class="activeItem" class="mt-3" v-if="($store.state.userData.user_active)" link exact
                @click="$router.push('/profile/companies')" color="primary">
                <v-list-item-title class="text-start font-weight-bold primary--text">
                  {{ $t('companies') | capitalize }}</v-list-item-title>
              </v-list-item>


              <v-list-item active-class="activeItem" class="mt-3" link exact @click="$router.push(`/profile`)"
                color="primary">
                <v-list-item-title class="text-start font-weight-bold primary--text">
                  {{ $t('my profile') | capitalize }}
                </v-list-item-title>
              </v-list-item>


              <v-list-item active-class="activeItem" class="mt-3" v-if="($store.state.userData.user_active)" link exact
                @click="$router.push('/profile/subscriptions')" color="primary">
                <v-list-item-title class="text-start font-weight-bold primary--text">
                  {{ $t('follow subscription') | capitalize }}
                </v-list-item-title>
              </v-list-item>


              <v-list-item active-class="activeItem" class="mt-3" color="error" @click="changeLanguage">
                <v-list-item-title class="text-start font-weight-bold primary--text">
                  {{ $vuetify.rtl == true ? $t('english') : $t('عربي') | capitalize }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item active-class="activeItem" class="mt-3" color="error" @click="$api.LOGOUT(true)">
                <v-list-item-title class="text-start font-weight-bold error--text">{{ $t('logout') | capitalize
                  }}</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" lg="9" class="px-0 px-lg-2">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>`
  </section>
</template>
 
<script>

export default {
  name: "ProfilePage",

  components: {

  },
  computed: {
    isAuth() {
      return this.$store.state.isAuth
    }
  },
  data: () => ({
    data: null,
    title: 'my profile',
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },
  mounted() {
    if (!this.isAuth) {
      this.$router.push('/')
    }
  },
  watch: {

  },
  methods: {
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },

}
</script>
 

<style lang="scss" scoped>
#profile-page {
  background: #e1f1f8;
  background: -webkit-linear-gradient(to left, #eef8fc, #e1f1f8);
  background: linear-gradient(to left, #eef8fc, #e1f1f8);
  padding-top: 7rem;
  min-height: 100vh;
  width: 100vw;

  //  display: flex;
  //align-items: center;
  .activeItem {
    // border-right: 4px solid blue;
    background-color: #eef4fc !important;

    .primary--text {
      color: #236CC8 !important;
    }

    &::after {
      width: 5.22px;
      height: 10px;
      content: '';
      right: unset;
      left: 0;
      position: absolute;
      background-color: #236CC8 !important;
      border-radius: 4px 0px 0px 3px;
    }
  }

}
</style>