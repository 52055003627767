<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-5 px-7 rounded-lg shadow">
      <v-row justify="end" justify-lg="space-between" align="center">
        <v-col cols="12" sm="6" lg="auto">
          <div class="font-weight-bold text-h6">{{ $t('personal profile') | capitalize }}</div>
        </v-col>
        <v-col cols="12" sm="6" lg="auto" class="d-flex justify-end">
          <v-btn class="blue12 font-weight-bold blue1--text" v-if="!editForm" @click="editForm = true" depressed>
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('edit') }}
          </v-btn>
          <v-btn class="font-weight-bold mx-2 rounded-lg" width="120" large text color="error" v-if="editForm"
            @click="editForm = false" depressed>
            {{ $t('back') }}
          </v-btn>
          <v-btn class="font-weight-bold mx-2 rounded-lg" width="120" large color="success" v-if="editForm"
            @click="save" :loading="isLoading" :disabled="!(valid)" depressed>
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" v-if="!editForm">
        <v-col cols="12" md="12" lg="auto">
          <v-avatar color="light" size="125" class="d-block ma-auto">
            <!-- <img class="pa-1" :data-src="$api.serverUrl + $store.state.userData.image"
              :alt="$store.state.userData.full_name" /> -->

            <v-img width="125" :lazy-src="$store.state.endpointURL + $store.state.userData.image"
              v-if="($store.state.userData.image)" height="125" :alt="'Qawaem'"
              :src="$store.state.endpointURL + $store.state.userData.image">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                </v-row>
              </template>
            </v-img>
            <img src="@/assets/img/svg/user.svg" v-else height="125" width="125" :alt="'Qawaem'">


          </v-avatar>
        </v-col>
        <v-col cols="12" md="12" lg="auto" class="px-4 text-center text-lg-start">
          <div class="font-weight-bold text-h6">{{ $store.state.userData.full_name | capitalize }}</div>
          <div class="subtitle-1">{{ $store.state.userData.mobile | capitalize }}</div>
          <div class="subtitle-1">{{ $store.state.userData.email }}</div>
        </v-col>
      </v-row>


      <v-form ref="form" v-model="valid" class="py-5" v-if="editForm">
        <v-row>
          <v-col cols="12" md="12" lg="2">
            <v-avatar color="light" size="125" class="d-block ma-auto">
              <!-- <img class="pa-1" :data-src="$api.serverUrl + $store.state.userData.image"
                :alt="$store.state.userData.full_name" /> -->

              <v-img width="125" :lazy-src="$store.state.endpointURL + $store.state.userData.image"
                v-if="($store.state.userData.image)" height="125" :alt="'Qawaem'"
                :src="$store.state.endpointURL + $store.state.userData.image">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
              <img src="@/assets/img/svg/user.svg" v-else height="125" width="125" :alt="'Qawaem'">

            </v-avatar>
          </v-col>
          <v-col cols="12" md="12" lg="10" class="px-4">
            <v-row align="center">
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('first name') | capitalize }}</label>
                <GenericInput type="text" name="first_name" :value="form.first_name" @input="form.first_name = $event"
                  label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('last name') | capitalize }}</label>
                <GenericInput type="text" name="last_name" :value="form.last_name" @input="form.last_name = $event"
                  label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('email') | capitalize }}</label>
                <GenericInput type="email" name="email" :disabled="true" :value="form.email"
                  @input="form.email = $event" label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('mobile') | capitalize }}</label>
                <GenericInput type="mobile" name="mobile" :value="form.mobile" @input="form.mobile = $event" label=""
                  :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="12">
                <GenericInput type="dropzone" name="image" :value="form.image" @input="form.image = $event"
                  label="add your profile image" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('password') | capitalize }}</label>
                <GenericInput type="password" name="password" :value="form.password" @input="form.password = $event"
                  label="" :required="true" :isLoading="false" :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>
              <v-col cols="12" md="12" lg="6" class="py-0">
                <label class="pa-3">{{ $t('confirm password') | capitalize }}</label>
                <GenericInput type="password" name="confirm_password" :value="form.confirm_password"
                  @input="form.confirm_password = $event" label="" :required="true" :isLoading="false"
                  :cols="[12, 12, 12]">
                </GenericInput>
              </v-col>


            </v-row>
          </v-col>
        </v-row>
      </v-form>


    </v-card>
  </v-container>
</template>
 
<script>
import GenericInput from "@/components/ui/GenericInput.vue"
export default {
  name: "ProfileDataPage",


  data: () => ({
    editForm: false,
    isLoading: false,
    valid: false,
    title: 'my profile',
    form: {
      first_name: null,
      last_name: null,
      mobile: null,
      email: null,
      password: null,
      confirm_password: null,
      image: null,
    }
  }),
  head() {
    return {
      title: this.$i18n.t(this.title),

    }
  },

  mounted() {
    this.form = {
      first_name: this.$store.state.userData.first_name,
      last_name: this.$store.state.userData.last_name,
      mobile: this.$store.state.userData.mobile,
      email: this.$store.state.userData.email,
      image: this.$store.state.userData.image,
      password: null,
      confirm_password: null,
    };

  },
  components: {
    GenericInput
  },
  watch: {
    $route() {

      // this.getData();
    },
  },
  methods: {
    save() {
      this.$refs.form.validate()
      if (this.valid) {
        this.isLoading = true;
        this.form.full_name = this.form.first_name + ' ' + this.form.last_name;
        this.$api.POST_METHOD(`update_profile`, this.form).then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.$api.checkAuth()
            this.editForm = false
          } else {
            this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
          }
        })
      } else {
        this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
      }

    }
  },

}
</script>
 