import { render, staticRenderFns } from "./Website.vue?vue&type=template&id=4cca3c0d&scoped=true&"
import script from "./Website.vue?vue&type=script&lang=js&"
export * from "./Website.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cca3c0d",
  null
  
)

export default component.exports