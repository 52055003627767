<template>


  <div id="appBar" style="position: fixed; top : 0; z-index: 100;width:100vw" class="py-3"
    :class="{ 'activeNav shadow': isNavActive }">
    <v-container fluid class="px-lg-10">
      <v-row class="app-bar text-center  d-none d-md-flex" justify="space-between" align="center">


        <v-col cols="6" lg="5">
          <v-row>
            <v-col cols="auto" sm="auto" lg="auto" class="pa-sm-0 pa-auto">
              <v-btn text medium :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                class="font-weight-bold" :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive }"
                @click="scrollTo('landing-section'); $store.state.activeSection = 1">
                {{ $t("home") | capitalize }}
              </v-btn>
            </v-col>
            <v-col cols="auto" sm="auto" lg="auto" class="pa-sm-0 pa-auto" v-if="$store.state.services.length > 0">
              <v-btn text medium :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                class="font-weight-bold"
                :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive, 'active_section': $store.state.isFeaturesSection }"
                @click="scrollTo('feature-section'); $store.state.activeSection = 3">
                {{ $t("services") }}</v-btn>
            </v-col>
            <v-col cols="auto" sm="auto" lg="auto" class="pa-sm-0 pa-auto" v-if="$store.state.services.length > 0">
              <v-btn text medium :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"

              :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive, 'active_section': $store.state.isBlogSection }"
                class="font-weight-bold" to="/blogs">
                {{ $t("Blogs") }}
              </v-btn>
            </v-col>

            <v-col cols="auto" sm="auto" lg="auto" class="pa-sm-0 pa-auto" v-if="$store.state.applications.length > 0">
              <v-btn text medium :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                class="font-weight-bold"
                :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive, 'active_section': $store.state.isAppSection }"
                @click="scrollTo('app-section'); $store.state.activeSection = 5">
                {{ $t("apps") | capitalize }}
              </v-btn>
            </v-col>

            <v-col cols="auto" sm="auto" lg="auto" class="pa-sm-0 pa-auto">
              <v-btn text medium :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                class="font-weight-bold"
                :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive, 'active_section': $store.state.isAboutSection }"
                @click="scrollTo('about-section'); $store.state.activeSection = 2">
                {{ $t("about the company") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="2" class="pa-0">
          <v-row>
            <v-col cols="auto">
              <router-link to="/">
                <v-img width="70" :lazy-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo"
                  v-if="($store.state.website_basic_data.main_logo)" :alt="'Qawaem'"
                  :src="$store.state.endpointURL + $store.state.website_basic_data.main_logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                    </v-row>
                  </template>
                </v-img>
                <img src="@/assets/img/default.svg" v-else height="70" width="70" :alt="'Qawaem'">
              </router-link>
            </v-col>
            <v-col cols="auto">
              <div class="cursor_pointer" :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive }"
                @click="changeLanguage">{{ $i18n.locale == 'ar' ? "EN" : "عربي" }}</div>
            </v-col>
          </v-row>
        </v-col>


        <v-col cols="4" v-if="!$store.state.isAuth">
          <v-row justify="end">
            <v-col cols="auto" sm="auto" lg="auto">
              <v-btn depressed medium :min-width="130"
                :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                :color="isNavActive ? 'gray3' : 'white'" :class="{ 'white--text': isNavActive }"
                @click="$router.push('/register')" class="rounded-lg font-weight-bold">
                {{ $t("start experimenting") }}
              </v-btn>
            </v-col>
            <v-col cols="auto" sm="auto" lg="auto">
              <v-btn text medium :min-width="130"
                :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                @click="$router.push('/login')" class="blue4 rounded-lg white--text font-weight-bold">
                {{ $t("login") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>


        <v-col cols="4" v-if="$store.state.isAuth" class="px-4">
          <v-row justify="end" align="center">
            <v-col cols="auto">
              <v-btn text medium :large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                class="font-weight-bold" :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive }"
                @click="$router.push('/profile')">
                {{ $t("profile") | capitalize }}
              </v-btn>
              <!-- <v-btn color="transparent" depressed class="rounded-pill shadow transparent font-weight-bold"
                @click="$router.push('/profile')" icon>
                <v-avatar color="light" class="mx-2" size="45">
                  <v-img width="45" :lazy-src="$store.state.endpointURL + $store.state.userData.image"
                    v-if="($store.state.userData.image)" height="45" :alt="'Qawaem'"
                    :src="$store.state.endpointURL + $store.state.userData.image">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                      </v-row>
                    </template>
                  </v-img>
                  <img src="@/assets/img/svg/user.svg" v-else height="45" width="45" :alt="'Qawaem'">
                </v-avatar>
              </v-btn> -->
            </v-col>
            <v-col cols="auto" v-if="($store.state.isAuth && $store.state.userData.free_trial)">
              <v-btn :color="isNavActive ? 'gray3' : 'white'" :class="{ 'white--text': isNavActive }" depressed
                class="rounded-pill" @click="$router.push('/freetrial')">
                {{ $t('start free trial') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>

      </v-row>

      <div class="mx-auto overflow-hidden d-flex d-md-none">
        <v-app-bar color="transparent" dark>
          <div @click="toggleSidebar" class="cursor_pointer">
            <img src="@/assets/img/svg/hamberger.svg" alt="qawaem" />
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex">
            <div class="cursor_pointer caption mx-2"
              :class="{ 'white--text': !isNavActive, 'gray5--text': isNavActive }" @click="changeLanguage">{{
                $i18n.locale == 'ar' ? "EN" : "عربي"
              }}</div>
            <router-link to="/">
              <v-img width="70" :lazy-src="$store.state.endpointURL + $store.state.website_basic_data.main_logo"
                v-if="($store.state.website_basic_data.main_logo)" :alt="'Qawaem'"
                :src="$store.state.endpointURL + $store.state.website_basic_data.main_logo">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
              <img src="@/assets/img/default.svg" v-else height="70" width="70" :alt="'Qawaem'">
            </router-link>
          </v-toolbar-title>
        </v-app-bar>
      </div>
    </v-container>
  </div>
</template>
<script>


export default {
  name: "AppBar",
  computed: {
    isNavActive() {
      if (this.$route.path == '/' || this.$route.path == '/en' || this.$route.path == '/ar') {
        if (this.$store.state.windowSize.offsetTop >= 350) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }

    },
    activeSection() {
      if (this.$store.state.isHomeSection) {
        return 1;
      }
      if (this.$store.state.isAboutSection) {
        return 2;
      }
      if (this.$store.state.isFeaturesSection) {
        return 3;
      }
      if (this.$store.state.isWatchVideoSection) {
        return 4;
      }
      if (this.$store.state.isRequestSection) {
        return 5;
      }
      else {
        return 0;
      }
    }
  },
  data: () => ({
    drawer: false,
    group: null,
  }),

  components: {

  },
  mounted() {

  },
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    scrollTo(sectionName) {

      if (this.$route.path === '/') {
        if (this.$i18n.locale == 'ar')
          this.$vuetify.goTo(`#${sectionName}`)
        else
          this.$vuetify.goTo(`/${this.$i18n.locale}#${sectionName}`)
      } else {
        this.$router.push(`/${this.$i18n.locale}`);
        setTimeout(() => {
          this.$vuetify.goTo(`#${sectionName}`)
        }, 100);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.activeNav {
  background: hsla(0, 0%, 100%, .6) !important;
  position: relative;

  &:before {
    -webkit-backdrop-filter: blur(4px) saturate(200%);
    backdrop-filter: blur(4px) saturate(200%);
    z-index: -2;
    background: hsla(0, 0%, 100%, 0) linear-gradient(180deg, #f7faff, rgba(247, 250, 255, 0));
    transition: -webkit-backdrop-filter .25s ease-out;
    transition: backdrop-filter .25s ease-out;
    transition: backdrop-filter .25s ease-out, -webkit-backdrop-filter .25s ease-out;
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
  }

  &:after {
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    opacity: 1;
    z-index: -1;
    background: hsla(0, 0%, 100%, .66667);
    opacity: 0;
    transition: opacity .25s ease-out;
    background: hsla(0, 0%, 100%, 0) linear-gradient(180deg, #f7faff, rgba(247, 250, 255, 0));

  }
}

.active_section {
  // position: relative;
  // color: var(--primary) !important;
  text-shadow: 0 1px 20px var(--primary) !important;
  // &::before {
  //   content: " ";
  //   display: inline-block;
  //   width: 6px;
  //   height: 6px;
  //   border-radius: 7.5px;
  //   background-color: var(--primary);
  //   position: absolute !important;
  //   bottom: 11px !important;
  //   top: 78%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   opacity: 1 !important;
  // }
}

.logo {
  max-height: 85px !important;
}
</style>