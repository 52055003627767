<template>

<section>
  <v-container class="my-16">
    <h2 class="text-h5 font-weight-black ma-5">{{ $store.state.sections.watch_video.name | capitalize }}</h2>
    <v-col cols="12" class="video_container">
        <v-row justify="space-between" align="center">
            <v-col cols="12" md="6">
                <div class="video_box">
                    <video playsinline controls muted loop>
                      <source :src="$store.state.endpointURL + $store.state.sections.watch_video.video" type="video/webm">
                      Your browser does not support the video tag.sdf
                    </video>
                  </div>
            </v-col>
            <v-col cols="12" md="5">
                <p class="text-h6 text-md-h5" v-if="$store.state.sections.watch_video.title || $store.state.sections.watch_video.description"
                v-html="$store.state.sections.watch_video.title || $store.state.sections.watch_video.description" />
            </v-col>
      
          </v-row>
    </v-col>
  </v-container>
</section>

</template>


<script>
export default {
  name: "WatchVideoSection",
  props: {
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    onWatchVideoSection(entries) {
      this.$store.state.isWatchVideoSection = entries[0].isIntersecting;
    },
  },
};
</script>



<style lang="scss" scoped>
.video_container {
    box-shadow: 0px 4px 58px rgba(0, 0, 0, 0.1);
    padding: 60px 29px;
    border-radius: 15px;
    background: url("../../assets/img/svg/Group\ 1000001842.svg");
    background-repeat: no-repeat;
    background-position: left;
}

.video_box {
  //  max-width: 503px;
  //  max-height: 283px;
    position: relative;
}

.video_box::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 30px;
    background: url('../../assets/img/svg/Group\ 1000001843.svg');
    background-repeat: no-repeat;
    top: 50%;
    left: -8%;
}


@media (max-width:750px) {
    .video_box::before {
        background: unset;
    }
}

.video_box::after {
    content: "";
    position: absolute;
    width: 210px;
    height: 54px;
    background: url('../../assets/img/svg/Group\ 1000001844.svg');
    background-repeat: no-repeat;
    top: 94%;
    left: 50%;
}

@media (max-width:1264px) {
    .video_box::after {
        background: unset;
    }
}

.v-application--is-ltr {
    .video_box::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 30px;
        background: url('../../assets/img/svg/Group\ 1000001843.svg');
        background-repeat: no-repeat;
        top: 50%;
        left: unset;
        transform: rotate(180deg);
        right: -8%;
    }

    @media (max-width:750px) {
        .video_box::before {
            background: unset;
        }
    }

    .video_box::after {
        content: "";
        position: absolute;
        width: 210px;
        height: 54px;
        background: url('../../assets/img/svg/Group\ 1000001844.svg');
        background-repeat: no-repeat;
        top: 94%;
        left: unset;
        right: 50%;
    }

    @media (max-width:1264px) {
        .video_box::after {
            background: unset;
        }
    }
}

p {
    max-width: 439px;
    font-size: 35px;
}

@media (max-width:1000px) {
    p {
        margin-top: 25px;
    }
}

video {
    width: 100%;
    border-radius: 20px;
    outline: 3px solid black;
}
</style>