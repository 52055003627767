<template>
   <section id="free-page">
      <v-container>
         <v-row justify="center" >
         <v-col cols="12" class="mb-5" v-if="!isLoading">
            <div class="text-h5 font-weight-bold text-center">{{$t('welcome in Qawaem') | capitalize}}</div>
            <div class="caption mt-3 text-center">{{$t('minutes to begin') | capitalize}}</div>
         </v-col>
         <v-col cols="12" md="8" lg="7"  xl="5"  class="mb-7" >
            <CompanyForm :freeTrial="true" :backValueMethod="(status)=>{isLoading = status}" />
         </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>
import CompanyForm from '@/components/ui/CompanyForm.vue';


export default {
   name: "FreeTrialPage",
   components: {
    CompanyForm
},

   data: () => ({
      data: null,
      panel: [],
      title: 'free trial',
      isLoading: false,

   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {

   },
   methods: {

   },

}
</script>
 

<style lang="scss" scoped>
#free-page {
   background: #e1f1f8;  
   background: -webkit-linear-gradient(to left, #eef8fc, #e1f1f8);
   background: linear-gradient(to left, #eef8fc, #e1f1f8); 
   
   padding-top: 50px;
   min-height: 100vh;
   width: 100vw;
 //  display: flex;
 //  align-items: center;
   #auth-form{
      background: rgba(255, 255, 255, 0.55);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(61px);
      border-radius: 12px;
   }
   .container{
      margin-top: 4rem !important;
   }
}
</style>