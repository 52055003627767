<template>
  <section v-intersect="onLandingSection" id="landing-section">
    <div class="landing_sections">
      <div class="video_container pa-0" id="home">

         <!-- View video in desktop -->
        <video playsinline autoplay muted loop v-if="$store.state.sections.landing_section.background_type == 1"
          :class="$store.state.sections.landing_section.gif ? 'd-none d-md-block' : ''" id="video">
          <source :src="$store.state.endpointURL + $store.state.sections.landing_section.video" type="video/webm">
          Your browser does not support the video tag.sdf
        </video>

        <!-- View gif in mobile -->
        <v-img v-if="$store.state.sections.landing_section.background_type == 1" height="150vh" width="100vw" id="gif"
          alt="qawaem" class="d-md-none" :src="$store.state.endpointURL + $store.state.sections.landing_section.gif" />

        <!-- View img in desktop -->
        <v-img :class="$store.state.sections.landing_section.image_mobile ? 'd-none d-md-block' : ''"
          v-if="$store.state.sections.landing_section.background_type == 2" height="150vh" width="100vw" alt="qawaem"
          :src="$store.state.endpointURL + $store.state.sections.landing_section.image_desktop" />

        <!-- View img in mobile -->
        <v-img v-if="$store.state.sections.landing_section.background_type == 2" height="100vh" width="100vw"
          alt="qawaem" class="d-md-none"
          :src="$store.state.endpointURL + $store.state.sections.landing_section.image_mobile" />

        <div class="heading text-center">
          <h1 class="text-md-h3 text-xs-button font-weight-bold">
            {{ $store.state.sections.landing_section.title | capitalize }}
          </h1>
          <div class="text text-h6 pt-0 pt-md-5 white--text" v-html="$store.state.sections.landing_section.description" />
        </div>
      </div>

      <!-- img in small screens -->
      <div class="parallax d-flex d-lg-none">
        <img :class="$vuetify.rtl ? 'paralax_postion_ar' : 'paralax_postion'"
          :src="$store.state.endpointURL + $store.state.sections.landing_section.laptop_image"
          :alt="$store.state.sections.landing_section.title">
      </div>

      <div class="parallax_animation mb-16 d-none d-lg-flex " :style="'top:-' + ($store.state.windowSize.y - 300) + 'px'">

        <img :class="img_3" :src="$store.state.endpointURL + $store.state.sections.landing_section.mobile_image"
          :alt="$store.state.sections.landing_section.title" />

        <img :class="img_2" :src="$store.state.endpointURL + $store.state.sections.landing_section.laptop_image"
          :alt="$store.state.sections.landing_section.title" />

        <img :class="img_1" :src="$store.state.endpointURL + $store.state.sections.landing_section.tablet_image"
          :alt="$store.state.sections.landing_section.title" />

      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "LandingSection",
  props: {},
  data: () => ({
    watchScroll: [],
    opacityTarget: 1,
    animation_status: false,
    img_3: "",
    img_2: "",
    img_1: "",
  }),
  computed: {},
  watch: {
    animation_status() {
      if (this.animation_status) {
        this.img_3 = "animation_transation_one";
        this.img_2 = "animation_transation_two";
        this.img_1 = "animation_transation_three";
      }
      else {
        this.img_3 = "animation_default_one";
        this.img_2 = "animation_default_two";
        this.img_1 = "animation_default_three";
      }
    },
  },
  mounted() {
  },
  created() {
    if (this.$vuetify.breakpoint.name !== "xs") {
      window.addEventListener("scroll", this.scrollCount);
    }
  },
  destroyed() {
    if (this.$vuetify.breakpoint.name !== "xs") {
      window.removeEventListener("scroll", this.scrollCount);
    }
  },
  methods: {
    scrollCount() {
      let value = window.pageYOffset.toFixed(0);
      if (value <= 0) {
        // console.log("yes", this.opacityTarget);
        this.watchScroll = [];
        this.opacityTarget = 1;
        // this.img_3 = -200
      }
      this.watchScroll.push(value);
      if (this.watchScroll[this.watchScroll.length - 2] > value) {
        console.log("ScrollUp");
        // document.getElementById("video").style.opacity = this.opacityTarget = this.opacityTarget + 0.030;
        document.querySelector(".heading").style.opacity = this.opacityTarget = this.opacityTarget + 0.030;
        // document.getElementById("animation_img3").style.right = this.img_3 + 10 + "px";
      }
      else {
        console.log("ScrollDown");
        // document.getElementById("video").style.opacity = this.opacityTarget = this.opacityTarget - 0.030;
        document.querySelector(".heading").style.opacity = this.opacityTarget = this.opacityTarget - 0.030;
        // document.getElementById("animation_img3").style.right = this.img_3 - 10 + "px";
      }
      if (value > this.$store.state.windowSize.y - 450) {
        this.animation_status = true;
      }
      else if (value < this.$store.state.windowSize.y) {
        this.animation_status = false;
      }
      document.getElementById("video").style.bottom = value * 2 + "px";
      console.log("this.value", value);
    },
    onLandingSection(entries) {
      this.$store.state.isHomeSection = entries[0].isIntersecting;
    },
  },
  components: {}
};
</script>


<style lang="scss" scoped>
#landing-section {
  position: relative;
  // margin-top: 94px;

}

.text {
  display: -webkit-box;
  overflow: hidden;
  z-index: 2;

  @media screen and (max-width: 1024px) {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  font-size: 14px;
  line-height: 1.9;
  color : #fff !important
}

video {
  /* width: 100%; */
  position: sticky;
  bottom: 0;
  opacity: 1;
  height: 150vh;
  object-fit: fill;
  width: 100vw !important;

  @media screen and (max-width: 1280px) {
    height: 100vh;
  }


}

.overlayBG {
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  background: rgb(0 0 0 / 25%);
}

.landing_sections {
  position: relative;
}

.video_container {
  position: relative;
  overflow: hidden;
}

.heading {
  //min-width: 320px !important;
  z-index: 5;
}

.video_container .heading {
  position: absolute;
  color: white !important;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 80vw;

  @media screen and (max-width: 1280px) {
    min-width: 95vw;
  }
}

.paralax_postion {
  position: relative;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 67%;
  max-height: 600px;
}

.paralax_postion_ar {
  position: relative;
  top: 100%;
  left: 18%;
  transform: translate(-50%, -50%);
  width: 67%;
  max-height: 600px;
  z-index: 2;
}




.v-application--is-rtl {
  .parallax_animation {
    position: relative;
    // top: -228px;
    display: flex;
    justify-content: center;
  }

  .parallax_animation img:nth-child(1) {
    max-height: 600px;
    position: relative;
    left: -247px;
    z-index: 5;
  }

  .animation_transation_one {
    animation-name: imgOne-rtl;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  .animation_default_one {
    animation-name: imgDefaultOne-rtl;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes imgOne-rtl {
    0% {
      left: -247px
    }

    100% {
      left: 0px
    }
  }

  @keyframes imgDefaultOne-rtl {
    0% {
      left: 0px
    }

    100% {
      left: -247px
    }
  }

  .parallax_animation img:nth-child(2) {
    max-height: 600px;
    position: relative;
    top: -110px;
    z-index: 2;
    left: -8%;

  }

  .animation_transation_two {
    animation-name: imgTwo-rtl;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  .animation_default_two {
    animation-name: imgDefaultTwo-rtl;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes imgTwo-rtl {
    0% {
      top: -110px;
      left: -8%
    }

    100% {
      top: 0px;
      left: 0%
    }
  }

  @keyframes imgDefaultTwo-rtl {
    0% {
      top: 0px;
      left: 0%
    }

    100% {
      top: -110px;
      left: -8%
    }
  }

  .parallax_animation img:nth-child(3) {
    max-height: 600px;
    position: relative;
    right: -217px;
    z-index: 5;
  }

  .animation_transation_three {
    animation-name: imgThree-rtl;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  .animation_default_three {
    animation-name: imgDefaultThree-rtl;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes imgThree-rtl {
    0% {
      right: -217px
    }

    100% {
      right: 0px
    }
  }

  @keyframes imgDefaultThree-rtl {
    0% {
      right: 0px
    }

    100% {
      right: -217px
    }
  }
}

.v-application--is-ltr {


  .parallax_animation {
    position: relative;
    top: -228px;
    display: flex;
    justify-content: center;
  }

  .parallax_animation img:nth-child(1) {
    max-height: 600px;
    position: relative;
    left: 275px;
    z-index: 1;
  }

  .animation_transation_one {
    animation-name: imgOne;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  .animation_default_one {
    animation-name: imgDefaultOne;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes imgOne {
    0% {
      left: 275px
    }

    100% {
      left: 0px
    }
  }

  @keyframes imgDefaultOne {
    0% {
      left: 0px
    }

    100% {
      left: 275px
    }
  }

  .parallax_animation img:nth-child(2) {
    max-height: 600px;
    position: relative;
    top: -110px;
    z-index: 0;
    left: 10%;

  }

  .animation_transation_two {
    animation-name: imgTwo;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  .animation_default_two {
    animation-name: imgDefaultTwo;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes imgTwo {
    0% {
      top: -110px;
      left: 10%
    }

    100% {
      top: 0px;
      left: 0%
    }
  }

  @keyframes imgDefaultTwo {
    0% {
      top: 0px;
      left: 0%
    }

    100% {
      top: -110px;
      left: 10%
    }
  }

  .parallax_animation img:nth-child(3) {
    max-height: 600px;
    position: relative;
    right: 190px;
  }

  .animation_transation_three {
    animation-name: imgThree;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  .animation_default_three {
    animation-name: imgDefaultThree;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  @keyframes imgThree {
    0% {
      right: 190px
    }

    100% {
      right: 0px
    }
  }

  @keyframes imgDefaultThree {
    0% {
      right: 0px
    }

    100% {
      right: 190px
    }
  }
}
</style>