<template>

  <footer>
    <v-container fluid class="px-4 px-md-5 py-0 px-lg-16" >
      <div class="footerEffectOne"></div>
      <div class="footerEffectTwo"></div>
      <v-row class="footerContainer justify-space-between">
        <v-col cols="12" md="5" lg="4" class="mt-6">
          <div class="footer-row">
            <div class="logo">
              <v-col cols="12" md="3" v-lazy-container="{ selector: 'img' }"
                v-if="$store.state.website_basic_data.footer_logo">
                <img :data-src="$store.state.endpointURL + $store.state.website_basic_data.footer_logo"
                  class="d-block ma-auto" height="130" alt="Qawaem" />
              </v-col>
            </div>
            <!-- <animated-logo /> -->
            <p class="gray3--text">
              {{ $store.state.website_basic_data.footer_desc | capitalize }}
            </p>

            <div class="social d-none d-md-flex">
              <a :href="$store.state.website_basic_data.linkedin_link" target="_blank">
                <img src="@/assets/img/svg/linkedin.svg" alt="linkedin" height="24" />
              </a>
              <a :href="$store.state.website_basic_data.twitter_link" target="_blank">
                <img src="@/assets/img/svg/twitter.svg" alt="twitter" height="24" />
              </a>
              <a :href="$store.state.website_basic_data.instagram_link" target="_blank">
                <img src="@/assets/img/svg/instagram.svg" alt="instagram" height="24" />
              </a>
              <a :href="$store.state.website_basic_data.facebook_link" target="_blank">
                <img src="@/assets/img/svg/facebook.svg" alt="facebook" height="24" />
              </a>
              <a :href="`${'https://wa.me/'}${$store.state.website_basic_data.whatsapp_link}`" target="_blank">
                <img src="@/assets/img/svg/whatsapp.svg" alt="whatsapp" height="24" />
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="3" md="2" lg="auto" class="mt-6">
          <div class="links-row d-flex flex-column mt-3">
            <h5 class="gray3--text text-center font-weight-black py-3">{{ $t("Links") }}</h5>
            <v-btn text class="gray3--text text-caption text-md-body-1 py-3"
              @click="scrollTo('landing-section'); $store.state.activeSection = 1">
              {{ $t("Home") | capitalize }}</v-btn>
            <v-btn text class="gray3--text text-caption text-md-body-1 py-3"
              @click="scrollTo('services-section'); $store.state.activeSection = 1">
              {{ $t("services") | capitalize }}</v-btn>
            <v-btn text class="gray3--text text-caption text-md-body-1 py-3"
              @click="scrollTo('app-section'); $store.state.activeSection = 1">
              {{ $t("apps") | capitalize }}</v-btn>
            <v-btn text class="gray3--text text-caption text-md-body-1 py-3"
              @click="scrollTo('footer-section'); $store.state.activeSection = 1">
              {{ $t("about the company") | capitalize }}</v-btn>
            <v-btn text class="gray3--text text-caption text-md-body-1 py-3" @click="$router.push('/login')"
              v-if="!$store.state.isAuth">
              {{ $t("start experimenting") | capitalize }}
            </v-btn>
            <v-btn text class="gray3--text text-caption text-md-body-1 py-3" @click="$router.push('/privacy')">
              {{ $t("privacy policy") | capitalize }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="9" md="5" lg="4" class="mt-6">
          <div class="contact-us-row d-flex flex-column  mt-3">
            <h5 class="gray3--text font-weight-black py-2">{{ $t("Contact Us") }}</h5>

            <!-- <span class="font-weight-bold text-caption text-md-body-1 py-2" v-for="contact in contact_us"
            :key="contact.id">
            {{ contact.description }}
          </span> -->
            <span class="font-weight-bold text-caption text-md-body-1 py-2">
              {{ $t('phone') | capitalize }} : {{ $store.state.website_basic_data.phone }}
            </span>
            <span class="font-weight-bold text-caption text-md-body-1 py-2">
              {{ $t('email') | capitalize }} : {{ $store.state.website_basic_data.email }}
            </span>
            <span class="font-weight-bold text-caption text-md-body-1 py-2">
              {{ $t('company phone') | capitalize }}: {{ $store.state.website_basic_data.company_phone }}
            </span>
            <span class="font-weight-bold text-caption text-md-body-1 py-2">
              {{ $t('sales contact number') | capitalize }}: {{ $store.state.website_basic_data.sales_phone }}
            </span>
            <span class="font-weight-bold text-caption text-md-body-1 py-2">
              {{ $t('support contact number') | capitalize }} : {{ $store.state.website_basic_data.support_phone }}
            </span>
            <span class="font-weight-bold text-caption text-md-body-1 py-2">
              {{ $t('commercial registration') | capitalize }} :
              {{ $store.state.website_basic_data.commercial_registration_no }}
            </span>


          </div>
        </v-col>
        <div class="social mobile d-flex d-md-none">
          <a :href="$store.state.website_basic_data.linkedin_link" target="_blank">
            <img src="@/assets/img/svg/linkedin.svg" alt="linkedin" height="24" />
          </a>
          <a :href="$store.state.website_basic_data.twitter_link" target="_blank">
            <img src="@/assets/img/svg/twitter.svg" alt="twitter" height="24" />
          </a>
          <a :href="$store.state.website_basic_data.linkedin_link" target="_blank">
            <img src="@/assets/img/svg/instagram.svg" alt="instagram" height="24" />
          </a>
          <a :href="$store.state.website_basic_data.facebook_link" target="_blank">
            <img src="@/assets/img/svg/facebook.svg" alt="facebook" height="24" />
          </a>
          <a :href="$store.state.website_basic_data.whatsapp_link" target="_blank">
            <img src="@/assets/img/svg/whatsapp.svg" alt="whatsapp" height="24" />
          </a>
        </div>
      </v-row>
      <v-row class="justify-center my-10">
        <footer class="gray7--text text-caption pa-2 text-center">
          {{ new Date().getFullYear() }} {{ $t('copyright') | capitalize }} ©
          {{ $t('designed by cloud secrets team') | capitalize }}
        </footer>
      </v-row>
    </v-container>
  </footer>


  <!-- <footer class="light">

    <v-container>
      <v-row align="center" justify="space-between">

        <v-col cols="12" sm="11" md="6" xl="5">
          <v-row align="center" justify="center">
            <v-col cols="12" md="3" v-lazy-container="{ selector: 'img'  }" v-if="$store.state.website_basic_data.footer_logo">
              <img :data-src="$store.state.endpointURL + $store.state.website_basic_data.footer_logo" class="d-block ma-auto" height="80"  alt="Qawaem" />
            </v-col>
            <v-col cols="12" md="9">
              <div class="body-1 text-center text-md-start">
                {{ $store.state.website_basic_data.footer_desc
                    | capitalize
                }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="11" md="6" xl="5" >
          <v-row justify="center" align="center">
            <v-col cols="auto">
              {{ $t('home') | capitalize }}
            </v-col>
            <v-col cols="auto">
              {{ $t('footer us') | capitalize }}
            </v-col>
            <v-col cols="auto">
              {{ $t('features') | capitalize }}
            </v-col>
            <v-col cols="auto">
              {{ $t('request demo') | capitalize }}
            </v-col>
          </v-row>
          <v-col cols="12" class="mt-2">
            <v-row justify="center">
              <v-col cols="auto" >

                <a :href="$store.state.website_basic_data.facebook_link" target="_blank">
                  <img src="@/assets/img/svg/face.svg" class="cursor_pointer" height="25" alt="Qawaem" />
                </a>
              </v-col>
              <v-col cols="auto">
                <a :href="$store.state.website_basic_data.twitter_link" target="_blank">
                  <img src="@/assets/img/svg/twitter.svg" class="cursor_pointer" height="25" alt="Qawaem" />
                </a>
              </v-col>
              <v-col cols="auto">
                <a :href="$store.state.website_basic_data.linkedin_link" target="_blank">
                  <img src="@/assets/img/svg/linkedin.svg" class="cursor_pointer" height="25" alt="Qawaem" />
                </a>
              </v-col>
              <v-col cols="12" md="auto" class="text-center">
                {{ $t('copyright') | capitalize }} ©
                {{ $t('designed by cloud secrets team') | capitalize }}
              </v-col>

            </v-row>
          </v-col>
        </v-col>

      </v-row>
    </v-container>
  </footer> -->
</template>


<script>

export default {
  name: "PageFooter",
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
  },
  beforeDestroy: function () {
  },
  methods: {},
  components: {}
};
</script>



<style scoped lang="scss">
footer{
  //height: 500px;
  overflow: hidden;
  position: relative;
}
.footerEffectOne {
  background: url("../assets/img/svg/dotts1.svg");
  position: absolute;
  width: 272px;
  min-height: 400px;
  right: 0;
  background-repeat: no-repeat;
}

.footerEffectTwo {
  background: url("../assets/img/svg/dashed2.svg");
  position: absolute;
  width: 390px;
  min-height: 450px;
  left: 0;
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  .footerEffectOne {
    background: url("../assets/img/svg/dashedMobile1.png");
    background-repeat: no-repeat;
    bottom: 677px;
    display: none;
  }

  .footerEffectTwo {
    background: url("../assets/img/svg/dashedMobile2.png");
    background-repeat: no-repeat;
  }

  .v-application .align-center {
    align-items: flex-start !important;
  }
}

.footerContainer {
  position: relative;
  z-index: 150;
}

.footer-row .logo img {
  max-height: 147px;
}

.social a {
  margin-right: 15px;
}

.contact-us-row,
.links-row {
  line-height: 1.9 !important;
}

p.gray3--text {
  max-width: 548px;
}
</style>
