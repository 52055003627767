<template>
   <section id="privacy-page" fluid>
     <v-container class="px-10" fluid>
       <v-row align="center">
         <v-col cols="12">
           <h1 class="text-h4 text-center text-md-start font-weight-bold position-relative">
             {{ $t('privacy policy') | capitalize }}
             <v-col cols="6" class="px-0">
               <v-divider class="title-divider mt-6" />
             </v-col>
           </h1>
           <div class="my-5 text-center text-md-start"
             v-html="$store.state.website_basic_data?.privacy_policy || '<p>-</p>'" />
         </v-col>
       </v-row>
     </v-container>
   </section>
 </template>
 
 <script>
 
 
 export default {
   name: "PrivacyPage",
 
   components: {
 
   },
 
   data: () => ({
     data: null,
     title: 'privacy policy',
     metaTitle: null,
     metaDescription: null,
     isLoading: false,
   }),
   mounted() {
     document.title = this.$i18n.t(this.title)
   },
   beforeDestroy(){
     document.title = this.$i18n.t('qawaem')
   },
   watch: {
     $route() {},
   },
   methods: {},
 
 }
 </script>
 
 <style scoped lang="scss">
 #privacy-page {
   padding-top: 120px; // header
   min-height: 100vh;
   position: relative;
   display: flex;
   align-items: center;
 }
 </style>
 