<template>
  <v-navigation-drawer v-model="$store.state.sidebarIsOpen" :right="$vuetify.rtl == true" :mobile-breakpoint="9910" app>
    <v-list nav dense>
      <v-list-item-group active-class="white--text blue13">
        <div class="d-flex justify-end cursor_pointer py-3">
          <v-icon color="gray10" size="25" @click="$store.state.sidebarIsOpen = false">mdi-close-thick </v-icon>
        </div>
        <v-list-item class="justify-end align-end py-2" v-if="$store.state.isAuth">
          <v-avatar color="light" class="mx-2" size="45">
            <!-- <img class="pa-1" :data-src="$api.serverUrl + $store.state.userData.image"
              :alt="$store.state.userData.full_name" /> -->
            <v-img width="45" :lazy-src="$store.state.endpointURL + $store.state.userData.image"
              v-if="($store.state.userData.image)" height="45" :alt="'Qawaem'"
              :src="$store.state.endpointURL + $store.state.userData.image">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                </v-row>
              </template>
            </v-img>
            <img src="@/assets/img/svg/user.svg" v-else height="45" width="45" :alt="'Qawaem'">
          </v-avatar>
          <v-list-item-title>
            <div class="gray6--text cursor_pointer subtitle-2" @click="$router.push(`/profile`)">
              {{ $store.state.userData.first_name | capitalize }} {{ $store.state.userData.last_name | capitalize }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="justify-end align-end py-2">
          <div><img src="@/assets/img/svg/icon-7.svg" /></div>
          <v-list-item-title>
            <v-btn text class="font-weight-bold" block
              @click="scrollTo('landing-section'); $store.state.activeSection = 1">
              {{ $t("home") | capitalize }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="justify-end align-end py-2" v-if="$store.state.services.length > 0">
          <div>
            <img src="@/assets/img/svg/icon-4.svg" />
          </div>
          <v-list-item-title @click="scrollTo('feature-section'); $store.state.activeSection = 3">
            <v-btn text class="font-weight-bold" block>
              {{ $t("services") | capitalize }}</v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="justify-end align-end py-2" v-if="$store.state.services.length > 0">
          <div>
            <img src="@/assets/img/svg/icon-4.svg" />
          </div>
          <v-list-item-title >
            <v-btn text class="font-weight-bold" block to="/blogs">
              {{ $t("Blogs") | capitalize }}</v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="justify-end align-end py-2"  v-if="$store.state.applications.length > 0">
          <div><img src="@/assets/img/svg/icon-2.svg" /></div>
          <v-list-item-title>
            <v-btn text class="font-weight-bold" block @click="scrollTo('app-section'); $store.state.activeSection = 5">
              {{ $t("apps") | capitalize }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="justify-end align-end py-2">
          <div><img src="@/assets/img/svg/icon.svg" /></div>
          <v-list-item-title>
            <v-btn text class="font-weight-bold" block
              @click="scrollTo('about-section'); $store.state.activeSection = 3">
              {{ $t("about the company") | capitalize }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <div class="text-center mt-5 py-2" v-if="!$store.state.isAuth">
          <v-btn text width="100%" height="55" class="rounded-lg gray3 white--text font-weight-bold"
            @click="$router.push(`/login`)">
            {{ $t("login") | capitalize }}
          </v-btn>
        </div>
        <div class="text-center mt-3 py-2" v-if="!$store.state.isAuth">
          <v-btn text width="100%" height="55" @click="$router.push(`/register`)"
            class="blue1 rounded-lg white--text font-weight-bold">
            {{ $t("start experimenting") | capitalize }}
          </v-btn>
        </div>
        <div class="text-center mt-3 py-2" v-if="($store.state.isAuth && $store.state.userData.free_trial)">
          <v-btn text width="100%" height="55" @click="$router.push(`/freetrial`)"
            class="blue1 rounded-lg white--text font-weight-bold">
            {{ $t('start free trial') }}
          </v-btn>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>


export default {
  name: "SideBar",
  computed: {

  },
  data: () => ({

  }),

  components: {

  },
  mounted() {

  },
  methods: {

    scrollTo(sectionName) {
      this.$store.state.sidebarIsOpen = false;
      if (this.$route.path === '/') {
        if (this.$i18n.locale == 'ar')
          this.$vuetify.goTo(`#${sectionName}`)
        else
          this.$vuetify.goTo(`/${this.$i18n.locale}#${sectionName}`)
      } else {
        this.$router.push(`/${this.$i18n.locale}`);
        setTimeout(() => {
          this.$vuetify.goTo(`#${sectionName}`)
        }, 100);
      }
    }

  },
};
</script>

