<template>
  <v-scroll-y-reverse-transition>
    <!--  -->
    <v-alert v-if="($store.state.isAuth && !$store.state.userData.user_active)" :color="codeIsSent ?'green1' : 'red1'" :border="$vuetify.rtl ? 'left' : 'right'" class="rounded-0" id="not-active-account">
      <!-- <h3 class="text-h5">
        {{ $t('your account and company was registered successfully') }}
      </h3>
      <v-divider class="my-4 success" style="opacity: 0.22"></v-divider> -->
      <v-col class="align-center white--text d-flex">
        <div>
          {{
              codeIsSent ? $t('activation code sent, please check your email') :
                $t('please active your account to start Qawaem free trial') | capitalize
          }}
        </div>
        <v-btn color="info" class="text-decoration-underline mx-1" @click="sendActivationCode" :loading="isLoading" text>
          {{ codeIsSent ? $t('send again') : $t('click here') }}
        </v-btn>
      </v-col>
    </v-alert>
  </v-scroll-y-reverse-transition>
</template>
<script>


export default {
  name: "UserNotActive",
  computed: {

  },
  data: () => ({
    isLoading: false,
    codeIsSent: false,
  }),

  components: {

  },
  mounted() {

  },
  methods: {
    sendActivationCode() {
      this.isLoading = true;
      this.$api.POST_METHOD(`send_user_activation_code`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.codeIsSent = true
        } else {
          this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
        }
      })
    }
  },
};
</script>

