<template>

  <section v-intersect="onAboutSection" id="about-section">
    <v-container fluid class="about-the-company mx-3 mx-md-10  my-16" id="about the company">
      <v-row class="rowEaseSection align-center justify-center d-flex">
        <v-col cols="12" md="6" class="about-the-company overflow-hidden">
          <div class="about-the-company-content ma-auto" data-aos="fade-left" data-aos-duration="1000">
            <h4 class="text-h5 text-lg-h4 gray3--text font-weight-bold mb-8">
              {{ $store.state.sections.about_us.title | capitalize }}
            </h4>
            <p class="text-body-2 text-lg-h6 gray7--text mb-8" v-html="$store.state.sections.about_us.description" />

          </div>
        </v-col>

        <v-col cols="12" md="6" class="ease-access">
          <v-img contain :lazy-src="$store.state.endpointURL + $store.state.sections.about_us.image" v-if="($store.state.sections.about_us.image)"
            :aspect-ratio="600/400" :alt="$store.state.sections.about_us.title" class="rounded-lg overflow-hidden mb-2 d-block ma-auto "
            :src="$store.state.endpointURL + $store.state.sections.about_us.image">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
              </v-row>
            </template>
          </v-img>
          <img src="@/assets/img/default.svg" v-else :alt="$store.state.sections.about_us.title">
        </v-col>
      </v-row>
    </v-container>
  </section>


  <!-- <section v-intersect="onAboutSection" data-aos="fade-up" data-aos-duration="1000">

    <v-container fluid id="features"
      class="benefits-section text-capitalize  white  pa-7 px-lg-16 px-md-1 px-xs-2 my-16">

      <header class="ma-11 text-center">
        <h2 class="text-h4 font-weight-black ma-5">{{ $store.state.sections.about_us.title | capitalize }}</h2>

      </header>
      <v-row class="row-cards justify-center">

        <v-col cols="12" sm="6" md="4" lg="4" data-aos="fade-down" data-aos-duration="1000">
          <div class="card mt-7">
            <div class="icon-card">



              <v-img contain :lazy-src="$store.state.endpointURL + $store.state.sections.about_us.image"
                v-if="$store.state.sections.about_us.image" height="300" :alt="$store.state.sections.about_us.title"
                class="rounded-lg overflow-hidden mb-2 d-block ma-auto "
                :src="$store.state.endpointURL + $store.state.sections.about_us.image">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
              <img src="@/assets/img/default.svg" v-else :alt="$store.state.sections.about_us.title">

            </div>
            <div class="title text-h6 gray1--text font-weight-bold text-center">
              {{ $store.state.sections.about_us.title | capitalize }}
            </div>
            <p class="text-subtitle-1 gray7--text font-weight-regular pa-3"
              v-html="$store.state.sections.about_us.description" />
          </div>
        </v-col>

      </v-row>

    </v-container>
  </section> -->
  <!-- <section id="about-section" v-intersect="onAboutSection" >

    <v-container data-aos="zoom-in" data-aos-duration="1000">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="6" lg="6" xl="5" v-lazy-container="{ selector: 'img'  }">
          <img :data-src="$store.state.endpointURL + $store.state.sections.about_us.image" alt="Qawaem">
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="5">
          <div class="text-h4 font-weight-bold">
            {{ $store.state.sections.about_us.title | capitalize }}
          </div>
          <div class="my-5" v-html="$store.state.sections.about_us.description" />
          <v-btn color="secondary" depressed class="mt-5" @click="scrollTo('request-section'); $store.state.activeSection = 4">{{ $t('request demo') }}</v-btn>
        </v-col>

      </v-row>
    </v-container>
  </section> -->
</template>


<script>
export default {
  name: "AboutSection",
  props: {
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    onAboutSection(entries) {
      this.$store.state.isAboutSection = entries[0].isIntersecting;
    },
    scrollTo(sectionName) {
      console.log(this.$route.path);
      if (this.$route.path === '/') {
        if (this.$i18n.locale == 'ar')
          this.$vuetify.goTo(`#${sectionName}`)
        else
          this.$vuetify.goTo(`/${this.$i18n.locale}#${sectionName}`)
      } else {
        this.$router.push(`/${this.$i18n.locale}`);
        setTimeout(() => {
          this.$vuetify.goTo(`#${sectionName}`)
        }, 100);
      }
    }
  },
};
</script>



<style lang="scss" scoped>
.about-the-company-content {
  // background: url("@/assets/Vector RTL.svg");
  background-repeat: no-repeat;
}

@media (max-width: 450px) {
  .about-the-company {
    order: 1;
    margin-top: 100px;
  }

  .about-the-company-content {
    height: 253px;
  }
}

.rowEaseSection p {
  line-height: 1.6;
}







.store-links {
  display: flex;
  max-width: 516px;
  justify-content: space-between;
  align-items: center;
}

.store-links a:nth-child(1) {
  img {
    border: 1px solid black;
    border-radius: 15px;
  }
}

@media (max-width: 450px) {
  .store-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .store-links img {
    width: 164px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>