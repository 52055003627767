import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Views :-
import Website from "@/views/Website/Website";
import Error500 from "@/views/Errors/Error500";
import Error404 from "@/views/Errors/Error404";

// Modules Children Routes :-

import { WebsiteModuleRoutes } from "@/router/WebsiteModuleRoutes";

Vue.use(VueRouter);

// Routes :-
const routes = [
  
  // Authorized :-
  {
    path: "/",
    component: Website,
    beforeEnter: (to, from, next) => {
      // store.state.splashScreen = false;
      store.dispatch("getWebsiteData").then();
      store.dispatch("checkAuth").then();
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
      }
      next();
      
    },
    children: [...WebsiteModuleRoutes],
  },
  {
    path: "/ar",
    component: Website,
    beforeEnter: (to, from, next) => {
    //  store.dispatch("changeRouteLanguage",'ar').then();
      // store.state.splashScreen = false;
      store.dispatch("getWebsiteData").then();
      store.dispatch("checkAuth").then();
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
      }
      next();
      
    },
    children: [...WebsiteModuleRoutes],
  },
  {
    path: "/en",
    component: Website,
    beforeEnter: (to, from, next) => {
   //   store.dispatch("changeRouteLanguage",'en').then();
      // store.state.splashScreen = true;
      store.dispatch("getWebsiteData").then();
      store.dispatch("checkAuth").then();
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
      }
      next();
      
    },
    children: [...WebsiteModuleRoutes],
  },

  // Error Pages  :-
  {
    path: "/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
    beforeEnter: (to, from, next) => {
      if(store.state.splashScreen){
        store.dispatch("getWebsiteData").then();
        store.dispatch("checkAuth").then();
        if (store.state.isAuth === true) {
          store.dispatch("getUserData").then();
        }
        next();
      }
      else{
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // store.commit("SET_ROUTE", to);
  next();
});

export default router;
