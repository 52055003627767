<template>
    <section id="app-section" v-intersect="onAppSection">
        <v-container class=" pa-0 py-16" id="apps">
            <v-card-title class="app__title justify-center font-weight-bold text-h6  gray4--text">
                <h2 class="font-weight-black text-capitalize">{{ $t("our applications") | capitalize }}</h2>
            </v-card-title>
            <v-row class="appRow text-center justify-center gray4--text text-subtitle-1 text-md-h5 mt-7">
                <v-col class="column-app ma-5" cols="10" md="5" v-for="(app, index) in $store.state.applications"
                    :key="index">
                    <div class="app-content">
                        <div class="app-image">

                            <v-img contain :lazy-src="$store.state.endpointURL + app.small_image"
                                v-if="(app.small_image || app.image)" :aspect-ratio="1280/620" :alt="app.title"
                                class="rounded-lg overflow-hidden mb-2 d-block ma-auto " style=""
                                :src="$store.state.endpointURL + app.image">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-skeleton-loader class="mx-auto" max-width="320"
                                            type="image"></v-skeleton-loader>
                                    </v-row>
                                </template>
                            </v-img>
                            <img src="@/assets/img/default.svg" v-else :alt="app.title">
                        </div>
                        <h3 class="mt-7">{{ app.title | capitalize }}</h3>
                        <p class="mt-5" v-html="app.description" />
                    </div>
                </v-col>

            </v-row>

        </v-container>
    </section>
</template>
  
<script>
export default {
    name: "OurApps",
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        onAppSection(entries) {
            this.$store.state.isAppSection = entries[0].isIntersecting;
        },
    }
};
</script>
  
<style lang="scss" scoped>
.card-app {
    position: relative;
    overflow: hidden;
    z-index: 200;
}

@media (max-width: 960px) {
    .card-app {
        height: 100% !important;
    }
}
.column-app{
    box-shadow: 0px 5.39px 31.79px rgba(0, 54, 230, 0.09);
    padding: 32px;
    border-radius: 10px;
}
.appRow {
    position: relative;
    height: 100%;
}


@media (max-width: 960px) {

    .card-app::before,
    .card-app::after {
        display: none;
    }
}

.app-image {
    max-height: 383px;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 660px) {
    .app-image {
        max-height: 361px;
    }

}

@media (max-width: 500px) {
    .app-image {
        max-height: 234px;
    }

}

.app-image img {
    max-height: 200px;

}

.close {
    position: relative;
    cursor: pointer;
    width: 50px;
    top: 60px;
}

@media (max-width: 600px) {
    .close {
        position: absolute;
        cursor: pointer;
        width: 29px;
        top: 9px;
        left: 15px;
    }

    .app__title {
        margin-top: 15px;
    }
}

.close img {
    width: 100%;
}
</style>
  