<template>
   <section id="auth-page">
      <v-container>
         <v-row align="center" justify="center">

            <v-col cols="11" sm="10" md="5" lg="7" xl="4">
               <v-row align="center" justify="center">
                  <v-col cols="12" lg="9" xl="8">
                     <div class="text-h4 text-center text-md-start font-weight-bold">
                        {{ $t('manage all your business and more in one program!') | capitalize}}
                     </div>
                  </v-col>
                  <v-col cols="12" lg="9" xl="8">
                     <div class="subtitle-1 text-center text-md-start gray8--text">
                        {{ $t('make your work easier with integrated software that allows all departments to work properly together.') | capitalize}}
                     </div>
                  </v-col>
               </v-row>
            </v-col>


            <v-col cols="11" sm="10" md="7" lg="5" xl="4">
               <v-card id="auth-form">
                  <v-form ref="form" v-model="valid" lazy-validation>

                     <v-card-title class="text-h6 font-weight-bold ma-auto d-block text-center">
                        <span>{{ $t('login') | capitalize }}</span>
                     </v-card-title>

                     <v-col cols="12" sm="12" class="py-0">
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3">{{ $t('email') | capitalize }}*</label>
                           <GenericInput type="email" name="email" :value="form.email" :keydownEnter="login"
                              @input="form.email = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3 mb-1">{{ $t('password') | capitalize }}*</label>

                           <GenericInput type="password" name="password" :value="form.password" :keydownEnter="login" 
                           @input="form.password = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                        </GenericInput>

                           <v-btn color="primary" text @click="$router.push('/forget')" plain>
                              {{ $t('forget password ?') }}</v-btn>
                        </v-col>

                     </v-col>

                     <v-col cols="12">
                        <v-btn color="primary" :width="180" :disabled="!valid" :height="50" @click="login"
                           class="shadow subtitle-1 font-weight-bold mx-auto d-block" :loading="isLoading == true">
                           {{ $t('login') }}</v-btn>
                     </v-col>
                     <v-col cols="12" class="d-md-flex d-block align-center justify-center">
                        <label class="d-block caption ma-auto text-center">{{ $t("don't have a registered account?") | capitalize
                        }}</label>
                        <v-btn class="d-block ma-auto" small color="primary" @click="$router.push('/register')" text
                           plain>{{ $t('to create a new account, click here') }}</v-btn>
                     </v-col>

                  </v-form>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>
import GenericInput from '@/components/ui/GenericInput.vue'


export default {
   name: "LoginPage",
   components: {
      GenericInput
   },

   data: () => ({
      data: null,
      title: 'login',
      isLoading: false,
      showPass: false,
      valid: false,
      form: {
         email: null,
         password: null,
      }
   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {
      this.passwordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v.length >= 5) || this.$t('Password must be greater than 5 characters'),
      ]
      this.confirmPasswordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v == this.newPassword) || this.$t('Password not match'),
      ]
   },
   methods: {
      login() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isLoading = true;
            this.$api.POST_METHOD(`login`, this.form).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  
                     this.$api.SET_AUTH(response.data);
                     this.$api.ShowModal('success', response.msg);
                     this.$router.push('/')
               } else {
                  this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
               }
            })
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }


      },

   },

}
</script>
 

<style lang="scss" scoped>
#auth-page {
   background: #e1f1f8;
   background: -webkit-linear-gradient(to left, #eef8fc, #e1f1f8);
   background: linear-gradient(to left, #eef8fc, #e1f1f8);

   padding-top: 50px;
   min-height: 100vh;
   width: 100vw;
   padding-top: 7rem;
   display: flex;
   align-items: center;

   #auth-form {
      background: rgba(255, 255, 255, 0.55);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(61px);
      border-radius: 12px;
   }
}
</style>