<template>
   <section id="auth-page">
      <v-container>
         <v-row justify="center" align="center" :justify-lg="viewOTP ? 'center' : 'space-around'">

            <v-col cols="11" sm="9" md="6" lg="7" xl="7" order="2" order-md="1"
               v-if="!viewOTP && $store.state.sections.register_page">
               <v-row align="center" justify="start">
                  <v-col cols="12" lg="9">
                     <div class="text-h5 text-center text-md-start font-weight-bold">
                        {{ $store.state.sections.register_page.name | capitalize }}
                     </div>
                  </v-col>
                  <v-col cols="12" lg="9">
                     <div class="text-h6 mb-3 text-center text-md-start">
                        {{ $store.state.sections.register_page.title | capitalize }}
                     </div>
                     <div class="subtitle-2 gray7--text" v-html="$store.state.sections.register_page.description" />
                  </v-col>
                  <v-col cols="12" lg="9">
                     <v-expansion-panels v-model="panel" multiple>
                        <v-row>
                           <v-col cols="12" lg="6" v-for="(item, i) in $store.state.register_briefs" :key="i">
                              <v-expansion-panel>
                                 <v-expansion-panel-header>
                                    {{ item.title | capitalize }}
                                 </v-expansion-panel-header>
                                 <v-expansion-panel-content class="body-2">
                                    {{ item.brief_description | capitalize }}
                                 </v-expansion-panel-content>
                              </v-expansion-panel>
                           </v-col>
                        </v-row>

                     </v-expansion-panels>
                  </v-col>
               </v-row>
            </v-col>


            <v-col cols="11" sm="auto" md="6" lg="5" xl="4" order="1" order-md="2">
               <v-card id="auth-form" class="pa-2">
                  <v-form ref="form" v-model="valid" lazy-validation>

                     <v-card-title class="text-h6 font-weight-bold px-8 ma-auto d-block"
                        :class="viewOTP ? 'success--text' : ''">
                        <div class="subtitle-1 font-weight-bold">
                           {{ viewOTP ? $t('register success') : $t('enter your details to start the experiment')| capitalize}}
                        </div>
                        <div class="caption gray2-text">
                           {{ viewOTP ? $t(responseMessage) : $t('free, simple steps and start using') | capitalize }}
                        </div>
                     </v-card-title>

                     <v-col cols="12" sm="12" class="py-0" v-if="viewOTP === false">
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3">{{ $t('email') | capitalize }}*</label>
                           <GenericInput type="email" name="email" :value="form.email" :keydownEnter="register"
                              @input="form.email = $event" :required="true" :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3 mb-1">{{ $t('password') | capitalize }}*</label>
                           <GenericInput type="password" name="password" :value="form.password" :keydownEnter="register"
                              @input="form.password = $event" :required="true" :isLoading="isLoading"
                              :cols="[12, 12, 12]" />
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2">

                           <label class="px-3 mb-1">{{ $t('confirm password') | capitalize }}*</label>
                           <GenericInput type="password" name="password" :value="form.confirm_password"
                              :keydownEnter="register" @input="form.confirm_password = $event" :required="true"
                              :isLoading="isLoading" :cols="[12, 12, 12]" />

                        </v-col>

                        <v-col cols="12" sm="12" class="py-2">
                           <vue-recaptcha :sitekey="sitekey" ref="recaptcha" :loadRecaptchaScript="true"
                              @verify="verifyMethod" @expired="expiredMethod" @render="renderMethod"
                              @error="errorMethod">
                           </vue-recaptcha>
                        </v-col>

                        <v-col cols="12" class="py-2">
                           <!-- :disabled="!valid" -->
                           <v-btn color="primary" :min-width="180" :disabled="!(valid && form.recaptcha_token)" :height="48" @click="register"
                              class="shadow subtitle-1 font-weight-bold" :loading="isLoading == true">
                              {{ $t('start your free trial now') }}</v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center pt-0 py-2">
                           <label class="caption">{{ $t("have a registered account?") | capitalize }}</label>
                           <v-btn class="" color="primary" small @click="$router.push('/login')" text plain>
                              {{ $t('to login, click here') }}
                           </v-btn>
                        </v-col>
                     </v-col>


                     <!-- OTP -->
                     <v-col cols="12" class="py-0" v-if="viewOTP">
                        <v-col cols="12" sm="12" class="py-2">
                           <label class="px-3 mb-1">{{ registeredEmail ? registeredEmail : $t('email') }}</label>
                           <GenericInput type="email" v-if="!registeredEmail" name="email" :value="form.email"
                              :keydownEnter="register" @input="form.email = $event" :required="true"
                              :isLoading="isLoading" :cols="[12, 12, 12]">
                           </GenericInput>


                        </v-col>
                        <v-col cols="12" sm="12" class="py-2" dir="ltr">
                           <!-- <label>{{ $t('OTP') }}</label> -->
                           <v-otp-input v-model="otp" focusable :key="otpKey" :length="6" type="number"
                              :disabled="loading" @finish="onFinish" />
                        </v-col>
                     </v-col>


                  </v-form>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>
import GenericInput from '@/components/ui/GenericInput.vue'
import { VueRecaptcha } from 'vue-recaptcha'


export default {
   name: "Register",
   components: {
      GenericInput,
      VueRecaptcha
   },

   data: () => ({
      data: null,
      panel: [],
      title: 'login',
      isLoading: false,
      showPass: false,
      showConfPass: false,
      viewOTP: false,
      otp: null,
      recaptchaToken: null,
      registeredEmail: null,
      sitekey: '6LcGvq8jAAAAAD1uHrbQnYG4ERrUBrsLK3vZ4E0o',  
      responseMessage: 'registered please activate and login',
      recaptchaValid: false,
      recaptchaResponse: null,
      form: {
         email: null,
         password: null,
         confirm_password: null,
         recaptcha_token: null,
      }
   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {
      // this.getRecaptchaToken();
      if (this.$route.query.email) {
         this.registeredEmail = this.$route.query.email
         this.viewOTP = true
      }

   },
   methods: {
      async getRecaptchaToken() {
         try {
            await this.$recaptcha.init()

         } catch (error) {
            this.$api.ShowModal('error', this.$i18n.t('reCaptcha error'));
         }
      },
      register() {
         if (this.form.recaptcha_token) {
            if (this.valid && this.form.recaptcha_token) {
               console.log(this.form);
               this.isLoading = true;
               this.$api.POST_METHOD(`register`, this.form).then((response) => {
                  this.isLoading = false;
                  if (response.check) {
                     this.$api.SET_AUTH(response.data);
                  //   if(response.msg){
                  //    this.$api.ShowModal('success', response.msg);
                  //   }
                     this.$router.push('/freetrial')
                  } else {
                     this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
                  }
               })
            } else {
               this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
            }
         } else {
            this.$api.ShowModal('error', this.$i18n.t('reCaptcha error'));
         }
      },

      onFinish() {
         if (this.otp) {
            this.$router.push(`/freetrial?email=${this.registeredEmail}&demo_code=${this.otp}`)
         }

      },

      errorMethod() { this.recaptchaValid = false },
      renderMethod() { this.recaptchaValid = false },
      expiredMethod() { this.recaptchaValid = false },
      verifyMethod(res) { this.recaptchaValid = true, this.form.recaptcha_token = res },

   },
   computed: {
      valid() {
         if (this.form.email && this.form.password && this.form.confirm_password) {
            return true;
         }
         else {
            return false;
         }
      }
   },
   beforeDestroy() {
      this.$recaptcha.destroy()
   },
}
</script>
 

<style lang="scss" scoped>
#auth-page {
   background: #e1f1f8;
   background: -webkit-linear-gradient(to left, #eef8fc, #e1f1f8);
   background: linear-gradient(to left, #eef8fc, #e1f1f8);

   padding-top: 50px;
   min-height: 100vh;
   width: 100vw;
   display: flex;
   align-items: center;

   #auth-form {
      background: rgba(255, 255, 255, 0.55);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(61px);
      border-radius: 12px;
   }

   .container {
      margin-top: 4rem !important;
   }
}
</style>