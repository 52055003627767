<template>
  <section id="feature-section" v-intersect="onFeatureSection" class="py-16">
    <v-container data-aos="fade-right" data-aos-duration="1000" fluid id="services"
      class="benefits-section text-capitalize  white px-lg-16 px-md-1 px-xs-7 my-16">

      <v-row class="row-cards justify-center text-center">
        <v-col cols="12">
          <v-card-title class="app__title justify-center font-weight-bold text-h6  gray4--text">
            <h2 class="font-weight-black text-capitalize">{{ $t("Solutions") | capitalize }}</h2>
          </v-card-title>
        </v-col>
        <v-col class="text-center d-flex " v-for="service in $store.state.services" :key="service.id"
          cols="12" sm="6" md="4" lg="3">
          <div class="card mt-7 pa-6 text-center">
            <div class="card-image"
            @click="$router.push(`/service/${service.title}/${service.id}`)">


              <v-img contain :lazy-src="$store.state.endpointURL + service.small_icon"
                v-if="(service.small_icon || service.icon)" max-height="200" :alt="service.title"
                @click="$router.push(`/service/${service.title}/${service.id}`)"
                class="rounded-lg overflow-hidden d-block ma-auto cursor_pointer"
                :src="$store.state.endpointURL + service.icon">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
                    <v-skeleton-loader class="mx-auto" max-width="320" type="image"></v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
              <img src="@/assets/img/default.svg" class="cursor_pointer" v-else :alt="service.title">



            </div>
            <p class="title text-body-1 gray1--text font-weight-bold  d-block ma-auto">
              {{ service.title | capitalize }}
            </p>
          </div>
        </v-col>



      </v-row>


    </v-container>

  </section>
</template>

<script>



export default {
  name: "ServicesSection",
  data: () => ({

  }),
  props: {
    'pageCode': Object,
  },
  methods: {
    onFeatureSection(entries) {
      this.$store.state.isFeaturesSection = entries[0].isIntersecting;
    },
  }
};
</script>

<style lang="scss" scoped>
.card-image img {
  max-height: 200px;
}

.benefits-section {
  position: relative;
  margin-top: -250px !important;
  z-index: 10;
  overflow: hidden;
}

@media (max-width:1300px) {
  .benefits-section {
    margin-top: -50px !important;
  }
}

.square-big {
  position: absolute;
  left: 246px;
  z-index: -2;
}

header {
  line-height: 3.6;
}

p {
  max-width: 700px;
  line-height: 1.6;
  margin: auto;
  display: -webkit-box;
  //overflow: hidden;
  //-webkit-line-clamp: 3;
  //-webkit-box-orient: vertical;
  transition: 0.3s;
  padding-top: 15px;
}

.icon-card {
  width: 90px !important;
  height: 90px !important;
  display: flex;
  border-radius: 50%;
  background: #ffffff;
  margin: 4px auto 24px;
}

.icon-card img {
  max-height: 45px;
  width: 45px;
  margin: auto;
}

.card {
  width: 306px;
  margin: auto;
  height: fit-content;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 5.39px 31.79px rgba(0, 54, 230, 0.09);
  border-radius: 20.3843px;
  border-radius: 23px;
}

.carousel-3d-slide {
  background: white;
  border: 1px solid blue;
  text-align: center;
  border-radius: 15px;
}

@media (max-width:650px) {
  .carousel-3d-slide img {
    width: 100%;
    max-height: 160px;
  }
}
</style>
