// Website Module Routes:-
import HomeModule from "@/views/Website/Home/HomeModule";
import ServicePage from "@/views/Website/ServicePage";
import Register from "@/views/Website/Register";
import Login from "@/views/Website/Login";
import Privacy from "@/views/Website/Privacy";
import ForgetPassword from "@/views/Website/ForgetPassword";
import FreeTrial from "@/views/Website/FreeTrial";
import ProfilePage from "@/views/Website/Profile/ProfilePage";
import Profile from "@/views/Website/Profile/Profile";
import Companies from "@/views/Website/Profile/Companies";
import Subscriptions from "@/views/Website/Profile/Subscriptions";


import BlogPage from "@/views/Website/BlogPage/BlogPage";
import FinancialConsulting from "@/views/Website/BlogPage/FinancialConsulting";

export const WebsiteModuleRoutes = [
  {
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
  },
  // service
  {
    path: "/service/:name/:id",
    name: "ServicePage",
    component: ServicePage,
    meta: {
      title: "service",
    },
  },
  {
    path: "/en/service/:name/:id",
    name: "ServicePage",
    component: ServicePage,
    meta: {
      title: "service",
    },
  },
  {
    path: "/ar/service/:name/:id",
    name: "ServicePage",
    component: ServicePage,
    meta: {
      title: "service",
    },
  },

  // BlogPage
  {
    path: "/blogs",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: "BlogPage",
    },
  },
  {
    path: "/en/blogs",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: "BlogPage",
    },
  },
  {
    path: "/ar/blogs",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: "BlogPage",
    },
  },

  // financial
  {

    path: "/financial-consulting/:id",
    name: "FinancialConsulting",
    component: FinancialConsulting,
    meta: {
      title: "FinancialConsulting",

    },
  },
  {

    path: "/en/financial-consulting/:id",
    name: "FinancialConsulting",
    component: FinancialConsulting,
    meta: {
      title: "FinancialConsulting",

    },
  },
  {

    path: "/ar/financial-consulting/:id",
    name: "FinancialConsulting",
    component: FinancialConsulting,
    meta: {
      title: "FinancialConsulting",

    },
  },

  // register

  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "register",
    },
  },
  {
    path: "/en/register",
    name: "Register",
    component: Register,
    meta: {
      title: "register",
    },
  },
  {
    path: "/ar/register",
    name: "Register",
    component: Register,
    meta: {
      title: "register",
    },
  },

  // login
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "login",
    },
  },
  {
    path: "/en/login",
    name: "Login",
    component: Login,
    meta: {
      title: "login",
    },
  },
  {
    path: "/ar/login",
    name: "Login",
    component: Login,
    meta: {
      title: "login",
    },
  },

  // privacy
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "privacy",
    },
  },
  {
    path: "/en/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "privacy",
    },
  },
  {
    path: "/ar/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "privacy",
    },
  },

  // forget
  {
    path: "/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "forget password",
    },
  },
  {
    path: "/en/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "forget password",
    },
  },
  {
    path: "/ar/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "forget password",
    },
  },

  {
    path: "/freetrial",
    name: "FreeTrial",
    component: FreeTrial,
    meta: {
      title: "free trial",
    },
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: {
      title: "my profile",
    },
    children: [
      {
        path: "/",
        name: "Profile",
        component: Profile,
        meta: {
          title: "profile",
        },
      },
      {
        path: "/profile/companies",
        name: "Companies",
        component: Companies,
        meta: {
          title: "companies",
        },
      },
      {
        path: "/profile/subscriptions",
        name: "Subscriptions",
        component: Subscriptions,
        meta: {
          title: "subscriptions",
        },
      },
    ],
  },

];
