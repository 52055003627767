<template>
  <v-col :dir="dir" :cols="cols ? cols[0] : 12" :sm="cols ? cols[0] : 12" :md="cols ? cols[1] : 6"
    :lg="cols ? cols[2] : 4" class="formControl d-flex py-1" :class="paddingY + ' ' + paddingX">
    <!--     @blur="(e) => onClickOuter(name, input, e.target.value, type, required, e)" --->
    <!--     v-on:change="onClickOuter(name, input, $event, type, required, $event)" --->
    <div style="width: 30%" v-if="labelInline == true">
      <label v-if="label && type !== 'checkbox' && type !== 'switch' && type !== 'dropzone'">
        {{ $t(label) | capitalize }}
        <!-- <span class="error--text" v-if="required == true">*</span> -->
      </label>
    </div>
    <div :style="labelInline == true ? 'width: 70%' : 'width: 100%'">
      <!-- Text  -->
      <v-text-field v-if="type == 'text'" v-model="input" outlined :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()"
        :class="classes" :disabled="disabled" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " :loading="isLoading" rounded-md :color="color" :append-icon="icon" :hide-details="true" :type="type"
        :placeholder="$i18n.t(placeholder)">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-text-field>
      <!--  Number -->
      <v-text-field v-if="type == 'number'" v-model.number="input" outlined :background-color= "backgroundColor" dense :name="name"
        @keydown.enter="keydownEnter()" :class="classes" :disabled="disabled" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " :loading="isLoading" rounded-md :color="color" :append-icon="icon" :hide-details="true" :type="type">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-text-field>
      <!-- Percentage -->
      <v-text-field v-if="type == 'percentage'" v-model.number="input" outlined :background-color= "backgroundColor" dense :name="name"
        @keydown.enter="keydownEnter()" :class="classes" :disabled="disabled" :rules="
          required == true
            ? $store.state.percentageRule
            : []
        " :loading="isLoading" rounded-md :color="color" :append-icon="icon" :hide-details="true" :type="'number'">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-text-field>

      <!-- Float Number -->
      <vuetify-money v-if="type == 'float'" v-model="input"  :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()"
        :class="classes" v-bind:placeholder="''" v-bind:disabled="disabled" v-bind:outlined="solo"
        v-bind:clearable="clearable" :label="$i18n.t(label)" :loading="isLoading" :append-icon="icon" :color="color"
        :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " :hide-details="true" v-bind:valueWhenIsEmpty="null">
      </vuetify-money>

      <!-- Date -->
      <v-menu v-if="type == 'date'" v-model="datePicker" outlined :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()"
        :class="classes" :close-on-content-click="false" :label="labelInline == true ? '' : $i18n.t(label)"
        :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="input" append-icon="mdi-calendar" @click:append="datePicker = true" outlined :background-color= "backgroundColor" dense
            :name="name" @keydown.enter="keydownEnter()" :color="color" :class="classes" readonly v-bind="attrs"
            v-on="on" :disabled="disabled" :clearable="clearable" :rules="
              required == true
                ? [(v) => !!v || $i18n.t('field is required')]
                : []
            " :loading="isLoading" rounded-md :hide-details="true">
            <template v-slot:label>
              <label v-if="labelInline == false">
                {{ $t(label) }}
                <!-- <span class="error--text" v-if="required == true">*</span> -->
              </label>
            </template>
          </v-text-field>
        </template>
        <v-date-picker color="primary" :min="minDate ? minDate : ''" :max="maxDate ? maxDate : ''"
          :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" v-model="input" @input="datePicker = false"></v-date-picker>
      </v-menu>

      <!-- Time -->
      <v-menu v-if="type == 'time'" v-model="timePicker" :close-on-content-click="false"
        :label="labelInline == true ? '' : $i18n.t(label)" :nudge-right="40" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="input" outlined :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()" :class="classes"
            :color="color" append-icon="mdi-clock-time-four-outline" @click:append="timePicker = true" readonly
            v-bind="attrs" v-on="on" :placeholder="$i18n.t(placeholder)" :disabled="disabled" :rules="
              required == true
                ? [(v) => !!v || $i18n.t('field is required')]
                : []
            " :loading="isLoading" class="roundedApp" :hide-details="true">
            <template v-slot:label>
              <label v-if="labelInline == false">
                {{ $t(label) }}
                <!-- <span class="error--text" v-if="required == true">*</span> -->
              </label>
            </template>
          </v-text-field>
        </template>
        <v-time-picker color="primary" format="ampm" :locale="$vuetify.rtl == true ? 'ar-EG' : 'en-UK'" v-model="input"
          @input="timePicker = false"></v-time-picker>
      </v-menu>

      <!-- Textarea -->
      <v-textarea v-if="type == 'textarea'" v-model="input" outlined :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()"
        :class="classes" :disabled="disabled" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " :loading="isLoading" rounded-md :no-resize="true" :color="color" :append-icon="icon" :hide-details="true"
        :rows="rowsNumber" :type="type">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-textarea>
      <!-- Email-->
      <v-text-field v-if="type == 'email'" v-model="input" outlined :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()"
        :class="classes" :disabled="disabled" :loading="isLoading" 
        :rules="
              required == true
                ? [(v) => !!(v || '').match(/@/) || $i18n.t('please enter valid email address')]
                : []
            " rounded-md :hide-details="true" type="email">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-text-field>
      <!-- Password-->
      <v-text-field v-if="type == 'password'" v-model="input" outlined :background-color= "backgroundColor" dense :name="name"
        @keydown.enter="keydownEnter()" :class="classes" :loading="isLoading" :disabled="disabled"
        :rules="required == true ? [(v) => !!v || $i18n.t('field is required')] : []" :color="color" rounded-md

        :type="showPassword ? 'text' : 'password'" :hide-details="true"
        @click:prepend-inner="showPassword = !showPassword">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-text-field>
      <!-- Mobile-->
      <v-text-field v-if="type == 'mobile' || type == 'phone'" v-model="input" outlined :background-color= "backgroundColor" dense :name="name"
        @keydown.enter="keydownEnter()" :class="classes" :loading="isLoading" :disabled="disabled"
        :rules="required == true ? [(v) => !!v || $i18n.t('field is required')] : []" rounded-md :hide-details="true" :min="0" :color="color"
        :append-icon="icon" type="tel">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-text-field>

      <!-- File -->

      <v-file-input v-if="type == 'file'" v-model="input" rounded-md :loading="isLoading" outlined :background-color= "backgroundColor" dense :name="name"
        @keydown.enter="keydownEnter()" :class="classes" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " @change="onImageChange($event)" :multiple="multi ? true : false" :disabled="disabled" :color="color"
        :append-icon="icon" chips :hide-details="true" small-chips truncate-length="20">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <span class="error--text" v-if="required == true">*</span>
          </label>
        </template>
        <template v-slot:selection>
          <span v-if="input">{{ $t('image loaded , you can delete or update it') }}</span>
        </template>
      </v-file-input>

      <div v-if="type == 'file' && hideImage == false && multi != true && input"
        class="overflow-hidden rounded-lg my-2">
        <div v-lazy-container="{ selector: 'img' }"
          v-if="fileExtension == 'jpeg' || fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'gif' || fileExtension == 'svg'">
          <img class="d-block ma-auto" style="max-height: 200px;" :key="input"
            :data-src="String(input).substring(0, 10) == 'data:image' ? input : $api.serverUrl + input" alt="qarat" />
        </div>
        <div v-else>
          <!-- <img v-if="fileExtension !== 'pdf' && fileExtension !== 'excell'" :src="require(`@/assets/img/png/files/${fileExtension}.png`)" height="200" class="d-block ma-auto"
          alt="qarat" /> -->
          <div v-if="fileExtension == 'pdf'">
            <iframe width='100%' height='350' :src='input' />
          </div>
          <div v-if="fileExtension == 'excell'">
            <!-- <ExcelTable /> -->
          </div>
        </div>
      </div>


      <!-- Select -->
      <v-autocomplete v-if="type == 'select'" outlined :background-color= "backgroundColor" dense :name="name" @keydown.enter="keydownEnter()"
        :class="classes" :items="lookups ? lookups : []" :item-text="selected_label" :item-value="selected_prop" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " v-model="input" :item-disabled="checkIsItemDisabled" :disabled="disabled"
        :no-data-text="$i18n.t('no selects')" :color="color" :append-icon="icon ? icon : 'mdi-menu-down'" :clearable="clearable || multi"
        :loading="isLoading" :chips="multi" :hide-details="true" :small-chips="multi" :multiple="multi ? true : false">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-autocomplete>
      <!-- Autocomplete -->
      <v-autocomplete v-if="type == 'autocomplete' || type == 'multiselect'" outlined :background-color= "backgroundColor" dense :name="name"
        @keydown.enter="keydownEnter()" :class="classes" v-model="input" :items="lookups ? lookups : []" :append-icon="icon ? icon : 'mdi-menu-down'"
        :item-text="selected_label" :item-value="selected_prop" :item-disabled="checkIsItemDisabled" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " :multiple="multi ? true : false" :no-data-text="$i18n.t('no selects')" :loading="isLoading"
        :disabled="disabled" :chips="multi" :hide-details="true" :clearable="clearable || multi" deletable-chips
        small-chips>
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-autocomplete>

      <!-- Checkbox -->
      <v-checkbox v-if="type == 'checkbox'" v-model="input" :disabled="disabled" hide-details :color="color"
        class="ma-0" :rules="
          required == true
            ? [(v) => !!v || $i18n.t('field is required')]
            : []
        " :true-value="1" :false-value="0">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-checkbox>

      <!-- Radio Group -->
      <v-radio-group v-if="type == 'radiogroup'" class="ma-0" hide-details row :rules="
        required == true
          ? [(v) => !!v || $i18n.t('field is required')]
          : []
      " :disabled="disabled" v-model="input">
        <v-radio :color="color" v-for="(select, index) in lookups" :key="index" :label="select[selected_label]"
          :value="select[selected_prop]"></v-radio>

        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-radio-group>

      <!-- Switch -->
      <v-switch v-if="type == 'switch'" v-model="input" inset :color="color" :rules="
        required == true
          ? [(v) => !!v || $i18n.t('field is required')]
          : []
      " hide-details :disabled="disabled" class="pa-0 ma-0" :true-value="1" :false-value="0">
        <template v-slot:label>
          <label v-if="labelInline == false">
            {{ $t(label) }}
            <!-- <span class="error--text" v-if="required == true">*</span> -->
          </label>
        </template>
      </v-switch>

      <!-- Texteditor -->
      <label v-if="labelInline == false && type == 'texteditor'">
        {{ $t(label) | capitalize }}
        <!-- <span class="error--text" v-if="required == true">*</span> -->
      </label>
      <!-- <vue-editor v-if="type == 'texteditor'" @blur="onClickOuter(name, input, $event, type, required, $event)"
        v-model="input"></vue-editor> -->

      <!-- dropzone -->
      <!-- <dropzone ref="el"  v-if="type == 'dropzone' && dropzoneOptions" :options="dropzoneOptions" :id="input"
        :useCustomSlot="true" :includeStyling="true" @vdropzone-file-added="onImageChange"
        @vdropzone-removed-file="onRemoveHappend" @vdropzone-max-files-exceeded="maxFilesReached" >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-upload-Img">
          </h3>
          <h3 class="dropzone-custom-title primary--text">{{ $i18n.t("Drag and drop to upload") }} {{ $i18n.t(label) }}
          </h3>
          <div class="subtitle">{{ $i18n.t("...or click to select from your computer") }}</div>
        </div>
      </dropzone> -->

      <Dropzone id="bar" v-if="type == 'dropzone'" ref="bar" :options="options"
      :includeStyling="true"  @vdropzone-file-added="onImageChange"
      @vdropzone-removed-file="onRemoveHappend" 
     :useCustomSlot="true">
        <div class="dropzone-custom-content">
          <v-row align="center">
            <v-col cols="auto" v-lazy-container="{ selector: 'img' }">
              <img class="pa-1" height="120" :data-src="value" :alt="''" />
            </v-col>
            <v-col cols="auto">
              <div class="subtitle-1 font-weight-bold text-start">{{ $t(label) | capitalize }} </div>
              <div class="body-2 text-start">
                {{ $t('drag the file and place it here, or click on upload from your device and choose the file from your files') | capitalize }} 
              </div>
              <div>
                <v-btn outlined :background-color= "backgroundColor" color="primary" class="d-block mt-3">
                  {{$t('upload from your device')}}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </Dropzone>


    </div>
  </v-col>
</template>

<script>

import Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "GenericInput",
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.type == "float") {
          this.$emit("input", Number(val));
        }
        else {
          this.$emit("input", val);
        }
      },
    },
    fileExtension() {
      if ((this.type == 'file' || this.type == 'dropzone') && this.input) {
        return this.$global.FILE_TYPE(this.input);
      } else {
        return null
      }
    }
  },
  mounted() {
    if (this.type == 'dropzone') {
      this.dropzoneOptions = {
        url: "http://localhost",
        headers: { "My-Awesome-Header": "header value" },
        // thumbnailWidth: 250,
        clickable: true,
        maxFilesize: 2,
        addRemoveLinks: true,
        dictRemoveFile: this.$i18n.t("remove"),
        maxFiles: this.multi == true ? 10 : 1,
        autoProcessQueue: false,
      };
      console.log('this.$refs.el.dropzone', this.$refs.bar.dropzone);
    }
  },
  watch: {
    value() {
      if (this.type == 'dropzone') {
        console.log('dropzone value', this.value);
      }
    }
  },
  methods: {
    async maxFilesReached(image) {
      console.log(image);
    },
    async onImageChange(image) {
      this.value =null;
      this.dropzoneKey++;
      var reader = new FileReader();
      // let multiImage = this.multi;
      // var base64arr = [];
      var images = new Promise(function (resolve, reject) {
        reader.readAsDataURL(image);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
          alert(error);
        };
      });
      if (await images) {

        this.selectedImage = await images;
          // console.log('bbbb', this.selectedImage);
          this.$emit("input", this.selectedImage);

          // this.$refs.myVueDropzone.disable();
      }
    },
    formatFloat() {
      setTimeout(() => {
        this.input = parseFloat(this.input).toFixed(2)
        console.log(this.input);
      }, 1);
    },
    async onRemoveHappend(image) {
      var reader = new FileReader();
      var images = new Promise(function (resolve, reject) {
        reader.readAsDataURL(image);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
          alert(error);
        };
      });

      if (await images) {
        const index = this.selectedImages.indexOf(await images);
        if (index > -1 && this.multi == true) {
          this.selectedImages.splice(index, 1);
          this.$emit("input", this.selectedImages);
        }
        if (this.multi == null || this.multi == false) {
          this.selectedImage = null;
          this.$emit("input", this.selectedImage);
          // this.$refs.myVueDropzone.enable();
        }
      }
    },
    checkIsItemDisabled(item) {
      if (this.disableItem) {
        if (item[this.disableItem.key] == this.disableItem.value) {
          return true;
        }
        return false;
      }
      else {
        return false;
      }

    },
  },
  components: {
    // UploadImages,
    // vueDropzone: vue2Dropzone,
    // VueEditor,
    // ExcelTable,
    Dropzone
  },
  props: {
    name: { default: null },
    dir: {
      default() {
        return this.$vuetify.rtl ? 'rtl' : 'ltr'
      },
    },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    cols: { default: null },
    required: { default: false },
    lookups_value: { default: null },
    multi: { default: null },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "primary" },
    icon: { default: "" },
    hideDetails: { default: true },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    disableItem: { default: null },
    minDate: { default: null },
    maxDate: { default: null },
    placeholder: { default: null },
    paddingY: { default: "py-0" },
    paddingX: { default: null },
    solo: { default: true },
    classes: { default: null },
    rowsNumber: { default: 3 },
    backgroundColor:  { default: 'backgroundW' },
  },
  data: () => ({
    showPassword: false,
    inputVal: false,
    selectedImages: [],
    selectedImage: null,
    dropzoneOptions: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    datePicker: false,
    timePicker: false,
    dropzoneKey: 1,
    options: {
      url: "http://localhost:3000/",
        headers: { "My-Awesome-Header": "header value" },
            }
    
  }),
};
</script>

<style lang="scss">
.theme--dark .vue-dropzone {
  background: transparent !important;
  border: 2px solid #181818;
}

.formControl .dropz .formControl .dropzone {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  border: 2px dashed #c2cdda;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.formControl .dz-error-mark,
.formControl .dz-error-message {
  display: none !important;
}

.dropzone .dz-preview .dz-image img {
  margin: auto;
}

.formControl .dropzone .dz-preview:not(.dz-processing) .dz-progress {
  display: none !important;
}

.dropzone .dz-preview.dz-image-preview {
  background: transparent;
  min-width: 270px;
}

.formControl .vue-dropzone>.dz-preview .dz-remove {
  right: 25%;
  left: 25%;
  border: 0;
  background: #ff4141;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
    opacity: 0.85;
  }
}

.dropzone .dz-message {
  text-align: center;
  margin: 0;
}

.formControl .dropzone-custom-title {
  margin-top: 0;
  // color: #00b782;
}

.formControl .subtitle {
  color: #314b5f;
}

.vue-dropzone>.dz-preview .dz-details {
  border-radius: 10px;
  background-color: #c2cdda;
}

.formControl .dropzone-custom-content {
  width: 100%;
  height: 100%;
  // background: #f5f6fa;
  // border: 0.5px solid #e1e1e1;
  border-radius: 5px;
  padding: 1rem 0;
  position: relative;

  .error {
    background-color: transparent !important;
    border-color: #ff4141 !important;
  }

  .plus {
    display: none;
  }

  .imgsPreview {
    display: flex;
    justify-content: center;
  }
}
</style>