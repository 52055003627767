import vuetify from "../plugins/vuetify";
import i18n from "../plugins/i18n";
import router from "../router";
export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    translation: localStorage.getItem("language") == "ar" ? i18n.messages.ar : i18n.messages.en,
    isRTL: vuetify.rtl,
    darkTheme: localStorage.getItem("darkTheme") == "true" ? true : false,
    requiredRule: [(v) => !!v || i18n.t("Field is required")],
    notRequiredRule: [],
    emailRule: [
      (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
    ],
    mobileRule: [
      (v) =>
        !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
        i18n.t("Please enter mobile number"),
    ],
    passwordRule: [
      (v) => !!v || i18n.t("Password is required"),
      (v) =>
        (v && v.length >= 5) ||
        i18n.t("Password must be greater than 5 characters"),
    ],
    favourites: localStorage.getItem("favourites")
      ? JSON.parse(localStorage.getItem("favourites"))
      : [],
  },
  mutations: {
    DARK_THEME(state, status) {
      state.darkTheme = status;
    },
    ACTIVE_LANGUAGE(state, lang) {
      state.language = lang;
      vuetify.framework.lang.current = lang;
      vuetify.framework.rtl = lang == 'en' ? false : true;
      state.translation = lang == "ar" ? i18n.messages.ar : i18n.messages.en;
      (i18n.locale = lang);
      localStorage.setItem("language", lang);
    },
    UPDATE_FAVOURITES(state, favourites) {
      state.favourites = favourites;
      setTimeout(() => {
        localStorage.setItem("favourites", JSON.stringify(state.favourites));
      }, 10);
    },
  },
  actions: {
    changeLanguage({ state, commit }) {
      // Get the current path
      let currentPath = router.currentRoute.path;

      // Define a regular expression to match '/ar' or '/en' at the start of the path
      const langPattern = /^\/(ar|en)/;

      // Remove the existing language prefix if it exists
      if (langPattern.test(currentPath)) {
        currentPath = currentPath.replace(langPattern, '');
      }

      // Determine the new language and update the state
      if (state.language == "en") {
        router.push(`/ar${currentPath}`);
        commit("ACTIVE_LANGUAGE", "ar");
        // window.location.reload(true);
      } else {
        router.push(`/en${currentPath}`);
        commit("ACTIVE_LANGUAGE", "en");
        // window.location.reload(true);
      }

    },
    // changeLanguage({ state , commit}) {
    //   if (state.language == "en") {
    //     router.push('/ar')
    //     commit("ACTIVE_LANGUAGE", "ar");
    //   } else {
    //     router.push('/en')
    //     commit("ACTIVE_LANGUAGE", "en");
    //   }
    // },
    darkTheme({ commit, state }) {
      localStorage.setItem("darkTheme", !state.darkTheme);
      commit("DARK_THEME", !state.darkTheme);
      return (vuetify.framework.theme.dark = state.darkTheme);
    },
    addFavourites({ commit, state }, favourites) {
      if (!state.favourites.some((fav) => fav.url === favourites.url)) {
        return commit("UPDATE_FAVOURITES", state.favourites.concat(favourites));
      } else {
        const removeIndex = state.favourites.findIndex(
          (fav) => fav.url === favourites.url
        );
        state.favourites.splice(removeIndex, 1);
        return commit("UPDATE_FAVOURITES", state.favourites);
      }
    },
  },
};
