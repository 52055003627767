<template>
   <section id="auth-page">
      <v-container>
         <v-row align="center" justify="center">

            <v-col cols="11" sm="10" md="5" lg="7" xl="4" v-if="viewMsg">
              <v-row align="center" justify="center">
               <v-col cols="12">
                  <div class="text-h4 success--text text-center  font-weight-bold">
                     {{ responseMessage | capitalize}}
                  </div>
               </v-col>
              </v-row>
            </v-col>
            <v-col cols="11" sm="10" md="5" lg="7" xl="4" v-if="!viewMsg">
              <v-row align="center" justify="center">
               <v-col cols="12" lg="9" xl="8">
                  <div class="text-h4 text-center text-md-start font-weight-bold">
                     {{$t('manage all your business and more in one program!') | capitalize}}
                  </div>
               </v-col>
               <v-col  cols="12" lg="9" xl="8">
                     <div class="subtitle-1 text-center text-md-start gray8--text">
                        {{$t('make your work easier with integrated software that allows all departments to work properly together.') | capitalize}}
                     </div>
               </v-col>
              </v-row>
            </v-col>


            <v-col cols="11" sm="10" md="7" lg="5"  xl="4" v-if="!viewMsg">
               <v-card  id="auth-form">
                  <v-form ref="form" class="pb-3 px-4" v-model="valid" lazy-validation>

   
                     <v-card-title class="text-h6 font-weight-bold ma-auto d-block" :class="viewOTP?'success--text' :''">
                        <div v-if="!viewNewPassword">{{  viewOTP? $t('enter code') :$t('forget password') |capitalize }}</div>
                        <div v-if="!viewNewPassword" class="caption gray2-text">{{viewOTP?  $t('a code sent to your email') : $t('a code will be sent to your email') |capitalize}}</div>
                        <div v-if="viewNewPassword">{{  $t('enter new password')  |capitalize }}</div>
                     </v-card-title>

                     <v-col cols="12" sm="12" class="py-0" v-if="!viewOTP && !viewNewPassword">
                        <v-col cols="12" sm="12">
                           <label>{{ $t('email') | capitalize}}*</label>
                           <v-text-field hide-details="auto" name="email" @keydown.enter="forget()" outlined background-color="white"
                              class="mt-4 rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                              v-model="form.email" dense rounded>
                           </v-text-field>
                        </v-col>

                     </v-col>

                     <v-col cols="12" v-if="!viewOTP && !viewNewPassword" >
                        <v-btn color="primary" :disabled="!(form.email)" :min-width="180" :height="50"  @click="forget" 
                        class="shadow subtitle-1 font-weight-bold mx-auto d-block" :loading="isLoading == true">
                           {{ $t('send to your email') }}</v-btn>
                     </v-col>
                     
                     
                     <!-- OTP -->
                     <v-col cols="12"  class="py-0" v-if="viewOTP">
                        <v-col cols="12" sm="12" class="py-2">
                           <label>{{ loginEmail ? loginEmail :$t('email') }}</label>
                           <v-text-field hide-details="auto" v-if="!loginEmail"  name="email" outlined background-color="white"
                              class="rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                              v-model="form.email" dense rounded>
                           </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" class="py-2" dir="ltr">
                           <!-- <label>{{ $t('OTP') }}</label> -->
                           <v-otp-input  v-model="otp"  :key="otpKey" :length="6" type="number"    :disabled="loading"
                           @finish="onFinish"   />
                        </v-col>
                        </v-col>

                        <v-col cols="12" sm="12" class="py-0" v-if="viewNewPassword">
                           <v-col cols="12" sm="12">
                              <label>{{ $t('new password') }}*</label>
                              <v-text-field hide-details="auto" name="password" type="password" @keydown.enter="changePassword()" outlined background-color="white"
                              class="mt-4 rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                                 v-model="password" dense rounded>
                              </v-text-field>
                           </v-col>
                           <v-col cols="12" sm="12">
                              <label>{{ $t('confirm password') }}*</label>
                              <v-text-field hide-details="auto" type="password" name="password" @keydown.enter="changePassword()" outlined background-color="white"
                              class="mt-4 rounded-sm" filled required color="primary" :rules="$store.state.requiredRule"
                                 v-model="confirm_password" dense rounded>
                              </v-text-field>
                           </v-col>
                           
                        </v-col>
                        
                        <v-col cols="12" v-if="viewNewPassword" >
                           <v-btn color="primary" :disabled="!(password &&  confirm_password)" :min-width="180" :height="50"  @click="changePassword" 
                           class="shadow subtitle-1 font-weight-bold mx-auto d-block" :loading="isLoading == true">
                              {{ $t('save new password') }}</v-btn>
                        </v-col>
                     </v-form>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
<script>

export default {
   name: "ForgetPassword",
   components: {

   },

   data: () => ({
      data: null,
      title: 'forget password',
      isLoading: false,
      showPass: false,
      valid: false,
      viewNewPassword: false,
      viewOTP: false,
      viewMsg: false,
      otp: null,
      loginEmail: null,
      password: null,
      confirm_password: null,
      responseMessage: 'please activate and login',
      form: {
         email: null,
      }
   }),
   head() {
      return {
         title: this.$i18n.t(this.title),
      }
   },
   mounted() {
      if(this.$route.query.token){
         this.checkForgetToken()
    }
      this.passwordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v.length >= 5) || this.$t('Password must be greater than 5 characters'),
      ]
      this.confirmPasswordRule = [
         v => !!v || this.$t('Password is required'),
         v => (v && v == this.newPassword) || this.$t('Password not match'),
      ]
   },
   methods: {
      forget() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isLoading = true;
            this.$api.POST_METHOD(`send_user_forget_code`, this.form).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.responseMessage = response.msg
                  this.loginEmail = response.data.email
                  this.viewMsg = true;
               }else{
                  this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
               }
            })
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }


      },
      onFinish() {
         if (this.otp) {
            this.viewOTP = false
            this.viewNewPassword = true
         }
      },
      changePassword() {
         this.$refs.form.validate()
         if (this.valid) {
            this.isLoading = true;
            const requestBody = {
               password : this.password,
               confirm_password : this.confirm_password,
               token : this.$route.query.token
            }
            this.$api.POST_METHOD(`change_forget_password`, requestBody).then((response) => {
               this.isLoading = false;
               if (response.check) {
                  this.$api.ShowModal('success', response.msg);
                  this.$router.push('/login')
               }else{
                  this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
               }
            })
         } else {
            this.$api.ShowModal('warning', this.$i18n.t('all field is required'));
         }

      },
      checkForgetToken() {
         this.$api.POST_METHOD(`check_forget_code`, {token : this.$route.query.token}).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.viewNewPassword = true;
            } else {
               this.$api.ShowModal('error', response.msg || this.$i18n.t("error500Msg"));
            }
         })
      }
   },

}
</script>
 

<style lang="scss" scoped>
#auth-page {
   background: #e1f1f8;  
   background: -webkit-linear-gradient(to left, #eef8fc, #e1f1f8);
   background: linear-gradient(to left, #eef8fc, #e1f1f8); 
   
   padding-top: 50px;
   min-height: 100vh;
   width: 100vw;
   padding-top: 7rem;
   display: flex;
  align-items: center;
   #auth-form{
      background: rgba(255, 255, 255, 0.55);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(61px);
      border-radius: 12px;
   }
}
</style>