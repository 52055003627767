<template>
  <v-container fluid class="pa-0">
    <LandingSection v-if="$store.state.sections.landing_section" />
    <ServicesSection v-if="($store.state.services.length > 0)" />
    <OurApps v-if="($store.state.applications.length > 0)" />
    <WhyQawaemSection v-if="$store.state.features.length > 0" />
    <WatchVideoSection v-if="$store.state.sections.watch_video" />
    <AboutSection v-if="$store.state.sections.about_us" />
  </v-container>
</template>


<script>
import LandingSection from '@/components/ui/LandingSection.vue';
import AboutSection from '@/components/ui/AboutSection.vue';
import WhyQawaemSection from '@/components/ui/WhyQawaemSection.vue';
import WatchVideoSection from '@/components/ui/WatchVideoSection.vue';
import ServicesSection from '@/components/ui/ServicesSection.vue';
import OurApps from '@/components/ui/OurApps.vue';
export default {
  name: "HomeModule",
  components: {
    LandingSection,
    AboutSection,
    WhyQawaemSection,
    WatchVideoSection,
    ServicesSection,
    OurApps,
  },
  data: () => ({

  }),
  watch: {

  },
  created() {

  },
  destroyed() {

  },
  computed: {},
  mounted() {
  },
  methods: {

  }

};
</script>


<style lang="scss" scoped>

</style>