<template>
   <section v-show="$store.state.social_media.length > 0">
      <v-speed-dial fixed v-model="fav" :bottom="true" :right="lang=== 'ar'"
         :left="lang === 'en'" style="min-width: 430px;"
         direction="top" :open-on-hover="false"
         :transition="'slide-y-reverse-transition'" >
         <template v-slot:activator>
            <v-btn :key="key" v-model="fab" style="animation : shake 300ms !important;" color="#2db742" dark fab>
               <v-scale-transition hide-on-leave>
                  <v-icon size="35" v-if="fav">mdi-close</v-icon>
               </v-scale-transition>
               <v-scale-transition hide-on-leave>
                  <v-icon size="35" v-if="!fav">mdi-whatsapp</v-icon>
               </v-scale-transition>
            </v-btn>
         </template>

         <v-card color="backgroundW" max-width="310" elevation="0" class="shadow-lg">
            <v-toolbar class="rounded-t-md rounded-b-0" elevation="0" dark color="#2db742" height="80">
               <v-row align="center">
                  <v-col cols="2" class="pe-0">
                     <v-icon size="35">mdi-whatsapp</v-icon>
                  </v-col>
                  <v-col cols="10" class="py-0">
                     <v-toolbar-title>{{ $t('Contact Us') }}</v-toolbar-title>
                     <div class="caption error-text">
                        {{ $t('Hello contact one of our representatives') }}
                     </div>
                  </v-col>
               </v-row>
            </v-toolbar>
            <div class="card_container pa-3">
               <div class="caption gray8--text">{{ $t('The team typically replies in a few minutes.') }}</div>
               <a :href="whatsapp.link" target="_blank" v-for="whatsapp in $store.state.social_media" :key="whatsapp.id">
                  <v-card v-if="whatsapp?.active" elevation="0" class="pa-3 userCard rounded-0 mt-2" color="#f5f7f9">
                     <v-row align="center">
                        <v-col cols="3">
                           <v-avatar class="blue12 pa-4">
                              <img src="@/assets/img/svg/user.svg" alt="Qawaem ERP">
                           </v-avatar>

                        </v-col>
                        <v-col cols="9" class="py-0">
                           <v-toolbar-title class="social_name text-capitalize body-2">{{ whatsapp[`name_${lang}`] }}</v-toolbar-title>
                        </v-col>
                     </v-row>
                  </v-card>
               </a>
            </div>
         </v-card>

      </v-speed-dial>

   </section>
</template>

<script>

export default {
   name: "Whatsapp",
   computed: {

   },
   components: {

   },
   watch: {

   },
   data() {
      return {
         fav: false,
         fab: false,
         lang: localStorage.getItem("language"),
         key: 1
      };
   },
   mounted() {

   },
   methods: {

   },
}
</script>

<style scoped  lang="scss">
section {
   position: relative;
   z-index: 5555555555555;
}

.userCard {
   border-right: 2px solid #2db742 !important;
}

.card_container {
   max-height: 300px;
   overflow-y: scroll;
}

.social_name {
   white-space: pre-wrap;
}</style>