<template>
  <section class="mt-16 blog-class">
    <v-row>
      <!-- Page Header -->
      <v-col cols="12" class="page-header ">
        <v-card class="d-flex flex-column justify-center align-center shadow-none pt-16" color="blue12"
          :height="checkScreenWidth ? 300 : 500">
          <h1 :class="checkScreenWidth && 'titleFontSizeMd'" class="title mt-2">{{ $t('Our blog has everything new') }}
          </h1>
          <div class="input-class">
            <GenericInput :keydownEnter="() => getSearch()" type="text" backgroundColor="#edf3f6" icon="mdi-magnify"
              classes="rounded-0 mt-2 mx-0 px-0" :dense="false" :solo="true"
              placeholder="I would like to know more about ..." name="name" :value="form.search" :clearable="true"
              @input="form.search = $event" :required="false" :isLoading="false" :cols="[12, 12, 12]" />
          </div>
          <div class="d-flex align-center" :class="checkScreenWidth ? 'mt-7' : 'mt-16 pt-8'">
            <img src="@/assets/blogs/mouse.svg" alt="">
            <h5 class="mx-2 scroll-down">{{ $t('Scroll down to More Readers') }}</h5>
          </div>

        </v-card>
      </v-col>
      <!-- Body -->
      <v-col cols="12" class="px-16 col-class"
        :class="[{ body_parent: isLoading }, !checkScreenWidth && 'd-flex align-center justify-center']">
        <v-row class="body mt-5" v-if="!isLoading">
          <v-col cols="12" md="3" class="px-10 tab-col-class">
            <v-card class="sidebar-card tab-col-class" elevation="0" width="100%">
              <h4 class="py-5 mx-3" :class="checkScreenWidth ? 'text-center' : ''">{{ $t('Elements') }}</h4>
              <v-tabs center-active v-model="activeTab" hide-slider active-class="tabActiveClass primary--text"
                :vertical="checkScreenWidth ? false : true" class="mx-3">
                <v-tab class="font-weight-bold rounded gray2--text d-flex justify-start align-center mb-3"
                  v-for="tabs in categories" :key="tabs?.id" height="56" @click="getCategory(tabs)">
                  <img src="@/assets/blogs/Page.svg" :alt="tabs?.name">
                  <v-tooltip bottom :open-delay="50">
                    <template v-slot:activator="{ on, attrs }">
                      <strong class="mx-2 text-truncate " v-bind="attrs" v-on="on"
                        :class="!checkScreenWidth && 'maxWidth'">
                        {{ $t(tabs?.name) }}
                      </strong>
                    </template>
                    <template v-slot:default="{ attrs }">
                      <div v-bind="attrs" v-on="on" class="wrap_tooltip">
                        <span>{{ $t(tabs?.name) }}</span>
                      </div>
                    </template>
                  </v-tooltip>
                </v-tab>
              </v-tabs>
            </v-card>
          </v-col>
          <v-col cols="12" md="9" class="mt-5">
            <v-row class="scrollable-container">
              <v-col cols="12" md="6" lg="4" v-for="cat in items" :key=cat?.id>
                <v-card @click="show(cat)" class="category-card rounded-lg shadow-none mx-auto" width="400"
                  height="300">
                  <v-img :src="endPoint(cat?.image) + cat?.image" width="100%" class="image-zoom"></v-img>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)" class="mb-11">
                    <span class="cat-type">{{ cat[`name_${$i18n.locale}`] }}</span>
                  </v-app-bar>
                  <v-card-text class="pa-0 ma-0 card-text shadow-none rounded-0">
                    <div class="font-weight-bold">
                      <h3 class="financial-consulting mt-2">{{ cat[`title_${$i18n.locale}`] }}</h3>
                      <p class="paragraph mt-7">{{ cat[`short_description_${$i18n.locale}`] }}</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="items.length == 0" cols="12" class="d-flex justify-center align-center">
                <div class="text-center">
                  <h2>{{ $t('We didnt find what youre looking for.') }}</h2>
                  <p>{{ $t('Continue searching, we have a lot of other articles that you will like') }}</p>
                </div>
              </v-col>

              <v-col cols=12 v-if="checkTotalCards">
                <div v-intersect="onIntersect"></div>
                <div class="d-flex justify-center my-5">
                  <v-progress-circular v-if="isIntersecting" indeterminate
                    color="indigo darken-2"></v-progress-circular>
                </div>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

        <AnimatedLoading :isLoading="isLoading" :height="85" :hideText="true" />

      </v-col>

    </v-row>
  </section>
</template>

<script>
import GenericInput from '../../../components/ui/GenericInput.vue';
import AnimatedLoading from '../../../components/ui/AnimatedLoading.vue';

export default {
  name: "BlogPage",
  data: () => ({
    form: {
      search: null
    },
    isLoading: false,
    activeTab: null,
    page: 1,
    rowsNumber: 10,
    isIntersecting: false,
    totalRows: null,
    categories: [],
    items: [],
  }),
  mounted() {
    this.getData()

  },
  computed: {

    checkTotalCards() {
      return this.items?.length < this.totalRows
    },

    checkScreenWidth() {
      return this.$vuetify.breakpoint.width < 960;
    },


    blog() {
      return this.$store.state.blog || [];
    },

    endPoint() {
      return (imgLink) => {
        if (imgLink?.startsWith('/')) {
          return this.$store.state.endpointURL.slice(0, -1)
        } else {
          return this.$store.state.endpointURL
        }
      };
    },
  },

  methods: {

    getData() {
      !this.isIntersecting && (this.isLoading = true);
      this.$api.GET_METHOD(`blogs?rows=${this.rowsNumber || ''}&page=${this.page++}&word=${this.form.search || ""}&category_id=${this.category_id || ''}`).then((response) => {
        this.isLoading = false;
        this.isIntersecting = false;
        if (response.check) {
          response.data.items.data.forEach((responseData) => {
            let existingProduct = this.items.find(product => product?.id == responseData?.id);
            if (existingProduct) {
              Object.assign(existingProduct, responseData);
            } else {
              this.items.push(responseData)
            }
          });

          this.totalRows = response.data.items.total
          this.categories = response.data.categories
          this.categories.unshift({ id: null, name: 'All' })
          console.log(response);
        }
      })
    },
    show(card) {
      this.$router.push(`/financial-consulting/${card.id}`);
    },
    getCategory(item) {
      this.category_id = item.id;
      this.form.search = null;
      this.items = [];
      this.page = 1;
      this.getData();
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    getSearch() {
      this.items = [];
      this.page = 1
      this.getData()
    },
  },

  watch: {
    isIntersecting() {
      if (this.isIntersecting && this.items?.length) {
        this.getData()
      }
    },
  },

  components: { GenericInput, AnimatedLoading },
}
</script>

<style lang="scss" scoped>
.scrollable-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90dvh !important;

  &::-webkit-scrollbar {
    width: 1px;
    opacity: 0;
  }
}

.blog-class {

  .page-header {

    .title {
      color: var(--Gray-2, #1D1929) !important;
      font-size: 48px !important;
      font-size: 32px !important;
      font-weight: 700 !important;
    }

    .titleFontSizeMd {
      font-size: 19px !important;
    }

    .input-class {
      height: 150px !important;
      display: flex !important;
      align-items: center !important;
    }

    .scroll-down {
      color: var(--Gray-6, #77757F) !important;
      font-size: 16px !important;
      font-weight: 700 !important;
    }

  }

  .body_parent {
    height: 50dvh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .body {

    .sidebar-card {
      border-radius: var(--3, 16px) !important;
      background: var(--White, #FFF) !important;
      box-shadow: 0px 9px 176px 0px rgba(0, 0, 0, 0.07) !important;
      overflow: hidden !important;

      .tabActiveClass {
        background-color: #e6ecff !important;
      }
    }

    .category-card {
      cursor: pointer !important;
      overflow: hidden !important;
      position: relative;

      .image-zoom {
        transition: transform 0.4s ease;
        position: absolute !important;
        width: 100%;
        height: 100%;
      }

      &:hover .image-zoom {
        transform: scale(1.3);
      }

      &:hover .card-text {
        background: rgba(16, 74, 166, 0.30) !important;
        backdrop-filter: blur(50px) !important;
        color: #fff !important;
      }

      .cat-type {
        color: #00294B;
        font-size: 15px;
        font-weight: 700;
        border-radius: 8px;
        background: #F9F9F9;
        box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.10);
        padding: 5px 8px;
        position: absolute !important;
      }

      .card-text {
        background: rgba(255, 255, 255, 0.70) !important;
        backdrop-filter: blur(50px) !important;
        height: 100%;
        padding: 30px 13px !important;
        color: #4A4754;
        transition: all .2s ease-in-out;
        position: absolute !important;

        .financial-consulting {
          white-space: nowrap;
          font-size: 21px;
          font-weight: 700;
          line-height: 20px;
        }

        .paragraph {
          font-size: 16px;
          font-weight: 700;
          line-height: 30px;
          overflow: hidden;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

      }

    }

  }

  .page-footer {
    .title {
      color: var(--Primery, #00294B) !important;
      font-weight: 700 !important;
      font-size: 45px !important;
      letter-spacing: 0.25px !important;
    }

    .titleFontSizeMd {
      font-size: 15px !important;
    }

    .your-guide {
      color: var(--Primery, #00294B) !important;
      text-align: center !important;
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 32px !important;
      letter-spacing: 0.25px !important;
      max-width: 750px !important;
    }

    .fontSizeMd {
      font-size: 11px !important;
    }

  }

  @media (max-width: 600px) {
    .col-class {
      padding: 0 20px !important;
    }

    .v-slide-group__next,
    .v-slide-group__prev {
      min-width: 100px;
    }
  }

  @media (max-width: 1296px) {

    .tab-col-class {
      padding: 0 5px !important;
    }

  }

  .maxWidth {
    text-align: initial;
    max-width: 190px !important;
  }
}
</style>