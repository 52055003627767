<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center">
        <v-col cols="auto">
          <img src="@/assets/img/svg/500.svg" alt="qawaem" />
        </v-col>
        <v-col cols="auto">
          <h1> {{ $t("Oops, something went wrong") }}.</h1>
          <p class="text--disabled my-3">
              {{ $t("error500Msg") }}.
          </p>
          <v-btn
            color="primary"
            @click="$router.go(-1)"
            class="mt-2"
            elevation="0"
          >{{ $t('Back previous page') }}</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  background-image: url("../../assets/img/svg/errorBg.svg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
}
</style>

<script>
export default {
  name: "Error500",
  computed: {},
};
</script>
